export const agreement = {
  exceptions: "",
  initialAgreement: false,
};

export const applicantInfoValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  muslimName: "",
  dob: "",
  citizen: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
  living: "",
  livingOther: "",
  rentAmount: "",
  filedBancrupcy: "",
  planForBankrupcy: "",
};

export const applicantStatus = {
  maritalStatus: "",
  hasCoApplicant: "",
  coApplicantLivesTogether: "",
  wifeIsCoApplicant: "",
};

export const coApplicantInfoValues = {
  coApplicantFirstName: "",
  coApplicantMiddleName: "",
  coApplicantLastName: "",
  coApplicantMuslimName: "",
  coApplicantDob: "",
  coApplicantAddress: "",
  coApplicantAddress2: "",
  coApplicantCity: "",
  coApplicantState: "",
  coApplicantZip: "",
  coApplicantCitizen: "",
  coApplicantLiving: "",
  coApplicantOtherLiving: "",
  coApplicantPhone: "",
  coApplicantEmail: "",
  coApplicantFiledBancrupcy: "",
  coApplicantPlanForBankrupcy: "",
};

export const emergencyReferencesValues = {
  emergencyName: "",
  emergencyRelationship: "",
  emergencyPhone: "",
  imamName: "",
  imamPhone: "",
  masjidName: "",
  referenceName1: "",
  referencePhone1: "",
  referenceName2: "",
  referencePhone2: "",
  referenceName3: "",
  referencePhone3: "",
};

export const employmentInfoValues = {
  currentlyEmployed: "",
  currentEmployer: "",
  currentPosition: "",
  employedSince: "",
  employerAddress: "",
  employerAddress2: "",
  employerCity: "",
  employerState: "",
  employerZip: "",
  employerPhone: "",
  supervisorName: "",
  previousEmployer: "",
  previousPosition: "",
  grossSalary: "",
  monthlyIncome: "",
};

export const coEmploymentInfoValues = {
  coApplicantCurrentlyEmployed: "",
  coApplicantCurrentEmployer: "",
  coApplicantCurrentPosition: "",
  coApplicantEmployedSince: "",
  coApplicantEmployerAddress: "",
  coApplicantEmployerAddress2: "",
  coApplicantEmployerCity: "",
  coApplicantEmployerState: "",
  coApplicantEmployerZip: "",
  coApplicantEmployerPhone: "",
  coApplicantSupervisorName: "",
  coApplicantPreviousEmployer: "",
  coApplicantPreviousPosition: "",
  coApplicantGrossSalary: "",
  coApplicantMonthlyIncome: "",
};

export const loanInfoValues = {
  loanRequested: "",
  loanPurpose: "",
  loanImpact: "",
  appliedForOtherLoan: "",
  downpayment: "",
  preferedHomeType: "",
  stayingInSameState: "",
  relocateWithinState: "",
};

export const applicantExpenseValues = {
  mortgageOrRent: "0",
  HOA: "0",
  cableOrInternet: "0",
  homePhone: "0",
  cellPhone: "0",
  homeUtilities: "0",
  groceries: "0",
  outing: "0",
  carLoanOrLease: "0",
  carMaintenance: "0",
  carInsurance: "0",
  homeInsurance: "0",
  healthInsurance: "0",
  pocketMedicalExpenses: "0",
  totalCreditCardDebt: "0",
  creditCardPayment: "0",
  studentLoansPayment: "0",
  tuitionForKids: "0",
  childSupportOrAlimony: "0",
  otherExpenses: "",
};

export const coApplicantExpenseValues = {
  coApplicantMortgageOrRent: "0",
  coApplicantHOA: "0",
  coApplicantCableOrInternet: "0",
  coApplicantHomePhone: "0",
  coApplicantCellPhone: "0",
  coApplicantHomeUtilities: "0",
  coApplicantGroceries: "0",
  coApplicantOuting: "0",
  coApplicantCarLoanOrLease: "0",
  coApplicantCarMaintenance: "0",
  coApplicantCarInsurance: "0",
  coApplicantHomeInsurance: "0",
  coApplicantHealthInsurance: "0",
  coApplicantPocketMedicalExpenses: "0",
  coApplicantTotalCreditCardDebt: "0",
  coApplicantCreditCardPayment: "0",
  coApplicantStudentLoansPayment: "0",
  coApplicantTuitionForKids: "0",
  coApplicantChildSupportOrAlimony: "0",
  coApplicantOtherExpenses: "",
};

export const loanSignatureValues = {
  signatureName: "",
  coSignatureName: "",
  agreeDate1: "",
  agreeDate2: "",
  loanAgreementCheck: false,
};

export const volunteerValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  skills: "",
  assistArea: "",
  signatureName: "",
  loanAgreementCheck: false,
  initialAgreement: false,
};

export const beKnownValues = {
  initialAgreement: false,
  fillingOutForSomeoneElse: "",
  whyFillingOutForSomeoneElse: "",
  firstName: "",
  lastName: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
  living: "",
  maritalStatus: "",
  householdNumber: "",
  householdMemberInfo: {},
  employed: "",
  businessOwner: "",
  grossSalary: "",
};

export const sponsorValues = {
  firstName: "",
  lastName: "",
  businessName: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
  grossSalary: "",
};

export const lenderValues = {
  firstName: "",
  lastName: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
  lendingAmount: "",
  willingToWait: "",
  agreeDate: "",
  signatureName: "",
  loanAgreementCheck: false,
  initialAgreement: false,
};

export const testData = {
  // EXCEPTIONS
  exceptions:
    "It would take us out of haram and interest and would make our lives better by allowing us to have financial freedom It would take us out of haram and interest and would make our lives better by allowing us to have financial freedom It would take us out of haram and interest and would make our lives better by allowing us to have financial freedom",
  initialAgreement: true,
  //APPLICANT INFO
  firstName: "Abdulla",
  middleName: "Anes",
  lastName: "Abdulla",
  muslimName: "",
  dob: "1996-07-29",
  citizen: "Yes",
  address: "4203 Par 3 Way",
  address2: "",
  city: "Lithonia",
  state: "GA",
  zip: "30038",
  phone: "4046187405",
  email: "alsharmani1@gmail.com", 
  living: "renting",
  filedBancrupcy: "No",
  planForBankrupcy: "No",
  // APPLICANT STATUS
  maritalStatus: "Yes",
  hasCoApplicant: "No",
  wifeIsCoApplicant: "Yes",
  coApplicantLivesTogether: "Yes",
  // CO APPLICANT STATUS
  coApplicantFirstName: "Ayah",
  coApplicantMiddleName: "",
  coApplicantLastName: "Almatery",
  coApplicantMuslimName: "",
  coApplicantDob: "2000-01-01",
  coApplicantCitizen: "No",
  coApplicantAddress: "",
  coApplicantAddress2: "",
  coApplicantCity: "",
  coApplicantState: "",
  coApplicantZip: "",
  coApplicantphone: "",
  coApplicantLiving: "",
  coApplicantRentAmount: "",
  coApplicantFiledBancrupcy: "",
  coApplicantPlanForBankrupcy: "",
  emergencyName: "Hesham Abdulla",
  emergencyRelationship: "Brother",
  emergencyPhone: "4046187402",
  imamName: "Imam Khalifa",
  imamPhone: "8549697858",
  masjidName: "Masjid At-Tawheed",
  referenceName1: "Uthman",
  referencePhone1: "5896989856",
  referenceName2: "Ahmed Ali",
  referencePhone2: "2356985878",
  referenceName3: "Khalid Ahmed",
  referencePhone3: "8548569858",
  //APPLICANT EMPLOYMENT
  currentlyEmployed: "Yes",
  currentEmployer: "Macy's",
  currentPosition: "Software Developer",
  employedSince: "2019-10-01",
  employerAddress: "4000 Johns Creek Ct",
  employerAddress2: "",
  employerCity: "Suwanee",
  employerState: "GA",
  employerZip: "30024",
  employerPhone: "4045586965",
  supervisorName: "Veera Gattu",
  previousEmployer: "Sharmani Web Design",
  previousPosition: "Software Developer",
  grossSalary: "88320",
  monthlyIncome: "7320",
  //CO APPLICANT EMPLOYMENT
  coApplicantCurrentlyEmployed: "Yes",
  coApplicantCurrentEmployer: "Travelers Inc",
  coApplicantCurrentPosition: "Agent",
  coApplicantEmployedSince: "2019-05-18",
  coApplicantEmployerAddress: "4524 Oakcliff Rd",
  coApplicantEmployerAddress2: "",
  coApplicantEmployerCity: "Atlanta",
  coApplicantEmployerState: "GA",
  coApplicantEmployerZip: "30035",
  coApplicantEmployerPhone: "4526896568",
  coApplicantSupervisorName: "Jake Smith",
  coApplicantPreviousEmployer: "",
  coApplicantPreviousPosition: "",
  coApplicantGrossSalary: "54522",
  coApplicantMonthlyIncome: "2000",
  //LOAN REQUEST
  loanRequested: "250000",
  loanPurpose: "Buy a home",
  loanImpact:
    "It would take us out of haram and interest and would make our lives better by allowing us to have financial freedom",
  appliedForOtherLoan: "No",
  downpayment: "200000",
  preferedHomeType: "House",
  stayingInSameState: "Yes",
  relocateWithinState: "Yes",
  //APPLICANT EXPENSES
  mortgageOrRent: "1500",
  HOA: "200",
  cableOrInternet: "200",
  homePhone: "0",
  cellPhone: "100",
  homeUtilities: "200",
  groceries: "1000",
  outing: "200",
  carLoanOrLease: "0",
  carMaintenance: "80",
  carInsurance: "150",
  homeInsurance: "0",
  healthInsurance: "200",
  pocketMedicalExpenses: "30",
  totalCreditCardDebt: "5000",
  creditCardPayment: "250",
  studentLoansPayment: "0",
  tuitionForKids: "0",
  childSupportOrAlimony: "0",
  otherExpenses: "0",
  //CO APPLICANT EXPENSES
  coApplicantMortgageOrRent: "0",
  coApplicantHOA: "0",
  coApplicantCableOrInternet: "0",
  coApplicantHomePhone: "0",
  coApplicantCellPhone: "0",
  coApplicantHomeUtilities: "0",
  coApplicantGroceries: "0",
  coApplicantOuting: "0",
  coApplicantCarLoanOrLease: "0",
  coApplicantCarMaintenance: "0",
  coApplicantCarInsurance: "0",
  coApplicantHomeInsurance: "0",
  coApplicantHealthInsurance: "0",
  coApplicantPocketMedicalExpenses: "0",
  coApplicantTotalCreditCardDebt: "0",
  coApplicantCreditCardPayment: "0",
  coApplicantStudentLoansPayment: "0",
  coApplicantTuitionForKids: "0",
  coApplicantChildSupportOrAlimony: "0",
  coApplicantOtherExpenses: "",
  // SIGNATURE
  signatureName: "Abdulla Abdulla",
  coSignatureName: "Aziza Hmud",
  agreeDate1: "2020-06-20",
};
