import React, { useEffect } from "react";
import { Form, Field, Formik, ErrorMessage, useFormikContext } from "formik";
import {
  dobLimit,
  objectEmpty,
  requiredString,
  renderStates,
} from "../../utils/helpers";
import * as Yup from "yup";
// import Debugger from "../utils/Debugger";
import FormError from "../../utils/FormError";

const CoApplicantInfo = ({ disableBtn, initialValues, setInitialValues }) => {
  const { coApplicantInfoValues, applicantStatus } = initialValues;

  const onChange = (e) => {
    setInitialValues({
      ...initialValues,
      coApplicantInfoValues: {
        ...initialValues.coApplicantInfoValues,
        [e.target.name]: e.target.value,
      },
    });
  };

  const validationSchema = Yup.object({
    coApplicantFirstName: requiredString,
    coApplicantLastName: requiredString,
    coApplicantDob: Yup.date().required("Required"),
    ...(applicantStatus.coApplicantLivesTogether === "No" && {
      coApplicantAddress: Yup.string().required("Required"),
      coApplicantAddress2: Yup.string(),
      coApplicantCity: requiredString,
      coApplicantState: requiredString,
      coApplicantZip: Yup.number()
        .required("Required")
        .typeError("Must be a number without any special characters"),
      coApplicantLiving: requiredString,
    }),
    coApplicantCitizen: requiredString,
    ...(coApplicantInfoValues.coApplicantLiving === "Other" && {
      coApplicantOtherLiving: requiredString,
    }),
    coApplicantPhone: Yup.number()
      .test(
        "len",
        "Must be exactly 10 digits",
        (val) => val && val.toString().length === 10
      )
      .typeError("Must be a number without any special characters"),
    coApplicantEmail: Yup.string().email("Must be a valid email."),
    coApplicantFiledBancrupcy: requiredString,
    coApplicantPlanForBankrupcy: requiredString,
  });

  const Context = () => {
    // Grab values and submitForm from context
    const { errors, validateForm } = useFormikContext();
    useEffect(() => {
      validateForm().then((errors) =>
        !objectEmpty(errors) ? disableBtn(true) : disableBtn(false)
      );
    }, [errors]);
    return null;
  };

  return (
    <>
      <div className="container">
        {/* <Debugger data={sectionTwo} /> */}

        <Formik
          initialValues={coApplicantInfoValues}
          validationSchema={validationSchema}
        >
          <Form onChange={onChange} id="form">
            <>
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label htmlFor="coApplicantFirstName">First Name</label>
                  <Field
                    className="form-control"
                    name="coApplicantFirstName"
                    placeholder="First Name"
                  />
                  <ErrorMessage
                    component={FormError}
                    name="coApplicantFirstName"
                  />
                </div>

                <div className="form-group col-md-3">
                  <label htmlFor="coApplicantMiddleName">Middle Name</label>
                  <Field
                    className="form-control"
                    name="coApplicantMiddleName"
                    placeholder="Middle Name"
                  />
                  <ErrorMessage
                    component={FormError}
                    name="coApplicantMiddleName"
                  />
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="coApplicantLastName">Last Name</label>
                  <Field
                    className="form-control"
                    name="coApplicantLastName"
                    placeholder="Last Name"
                  />
                  <ErrorMessage
                    component={FormError}
                    name="coApplicantLastName"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6 col-lg-4">
                  <label htmlFor="coApplicantMuslimName">
                    Muslim Name (if different)
                  </label>
                  <Field
                    className="form-control"
                    name="coApplicantMuslimName"
                  />
                  <ErrorMessage
                    component={FormError}
                    name="coApplicantMuslimName"
                  />
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="coApplicantDob">Date of Birth</label>
                  <Field
                    type="date"
                    className="form-control"
                    name="coApplicantDob"
                    placeholder="DOB"
                    max={dobLimit()}
                  />
                  <ErrorMessage component={FormError} name="coApplicantDob" />
                </div>
              </div>

              {applicantStatus.coApplicantLivesTogether === "No" && (
                <div className="form-row">
                  <div className="form-group col-lg-6">
                    <label htmlFor="coApplicantAddress">Street Address</label>
                    <Field
                      className="form-control"
                      name="coApplicantAddress"
                      placeholder="1234 Main St"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantAddress"
                    />
                  </div>

                  <div className="form-group col-lg-4">
                    <label htmlFor="coApplicantAddress2">
                      Suite, Apartment, Unit, etc.
                    </label>
                    <Field
                      className="form-control"
                      name="coApplicantAddress2"
                      placeholder="Apartment, studio, or floor"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantAddress2"
                    />
                  </div>

                  <div className="form-group col-lg-4">
                    <label htmlFor="coApplicantCity">City</label>
                    <Field className="form-control" name="coApplicantCity" />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantCity"
                    />
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="coApplicantState">State</label>
                    <Field
                      name="coApplicantState"
                      className="custom-select"
                      as="select"
                    >
                      <option value="">Select...</option>
                      {renderStates()}
                    </Field>
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantState"
                    />
                  </div>

                  <div className="form-group col-lg-2 col-md-4">
                    <label htmlFor="coApplicantZip">Zip</label>
                    <Field className="form-control" name="coApplicantZip" />
                    <ErrorMessage component={FormError} name="coApplicantZip" />
                  </div>
                </div>
              )}

              <div className="form-row">
                <div className="form-group col-md-8">
                  <label htmlFor="coApplicantCitizen">
                    Is your Co-Applicant a United States citizen?
                  </label>
                  <Field
                    className="custom-select col-lg-6 col-md-8"
                    name="coApplicantCitizen"
                    as="select"
                  >
                    <option value="">Select...</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Field>
                  <ErrorMessage
                    component={FormError}
                    name="coApplicantCitizen"
                  />
                </div>
              </div>

              {applicantStatus.coApplicantLivesTogether === "No" && (
                <div className="form-row">
                  <div className="form-group col-md-6 col-lg-4">
                    <label htmlFor="coApplicantLiving">
                      Current living status
                    </label>
                    <Field
                      className="custom-select"
                      name="coApplicantLiving"
                      as="select"
                    >
                      <option value="">Select...</option>
                      <option value="Renting">Renting</option>
                      <option value="Homeowner">Outright homeowner</option>
                      <option value="On a mortgage">On a mortgage</option>
                      <option value="Other">other</option>
                    </Field>
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantLiving"
                    />
                  </div>
                </div>
              )}

              {coApplicantInfoValues.coApplicantLiving === "Other" && (
                <div className="form-row">
                  <div className="form-group col-md-6 col-lg-4">
                    <label htmlFor="coApplicantOtherLiving">
                      Please explain
                    </label>
                    <Field
                      name="coApplicantOtherLiving"
                      className="form-control"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantOtherLiving"
                    />
                  </div>
                </div>
              )}

              <div className="form-row">
                <div className="form-group col-md-6 col-lg-4">
                  <label htmlFor="coApplicantPhone">Phone Number:</label>
                  <Field className="form-control" name="coApplicantPhone" />
                  <ErrorMessage component={FormError} name="coApplicantPhone" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6 col-lg-4">
                  <label htmlFor="coApplicantEmail">Email:</label>
                  <Field
                    className="form-control"
                    name="coApplicantEmail"
                    placeholder="example@email.com"
                  />
                  <ErrorMessage component={FormError} name="coApplicantEmail" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6 col-lg-4">
                  <label htmlFor="coApplicantFiledBancrupcy">
                    Has your Co-Applicant ever filed for bankrupcy?
                  </label>
                  <Field
                    className="custom-select"
                    name="coApplicantFiledBancrupcy"
                    as="select"
                  >
                    <option value="">Select...</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Field>
                  <ErrorMessage
                    component={FormError}
                    name="coApplicantFiledBancrupcy"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6 col-lg-4">
                  <label htmlFor="coApplicantPlanForBankrupcy">
                    Is your Co-Applicant planning on filing bankrupcy in the
                    next 12 months?
                  </label>
                  <Field
                    className="custom-select"
                    name="coApplicantPlanForBankrupcy"
                    as="select"
                  >
                    <option value="">Select...</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Field>
                  <ErrorMessage
                    component={FormError}
                    name="coApplicantPlanForBankrupcy"
                  />
                </div>
              </div>
            </>

            <Context />
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default CoApplicantInfo;
