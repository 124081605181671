import React, { useEffect } from "react";
import { Form, Field, Formik, ErrorMessage, useFormikContext } from "formik";
import {
  renderStates,
  objectEmpty,
  phone,
  requiredString,
  dobLimit,
} from "../../utils/helpers";
import * as Yup from "yup";
// import Debugger from "../utils/Debugger";
import FormError from "../../utils/FormError";
import { coEmploymentInfoValues as initialData } from "../../consts/formValues";

const CoApplicantEmployment = ({ disableBtn, initialValues, setInitialValues }) => {
  const { coEmploymentInfoValues } = initialValues;

  useEffect(() => {
    if (coEmploymentInfoValues.coApplicantCurrentlyEmployed !== "Yes") {
      document.getElementById("form").reset();
    }
    return;
  }, [coEmploymentInfoValues.coApplicantCurrentlyEmployed]);

  const onChange = (e) => {
    setInitialValues({
      ...initialValues,
      coEmploymentInfoValues: {
        ...initialValues.coEmploymentInfoValues,
        [e.target.name]: e.target.value,
      },
    });

    if (
      e.target.name === "coApplicantCurrentlyEmployed" &&
      e.target.value !== "Yes"
    ) {
      setInitialValues({
        ...initialValues,
        coEmploymentInfoValues: {
          ...initialValues.coEmploymentInfoValues,
          ...initialData,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const validationSchema = Yup.object({
    coApplicantCurrentlyEmployed: requiredString,
    ...(coEmploymentInfoValues.coApplicantCurrentlyEmployed === "Yes" && {
      coApplicantCurrentEmployer: requiredString,
      coApplicantCurrentPosition: requiredString,
      coApplicantEmployedSince: Yup.date().required("Required"),
      coApplicantEmployerAddress: Yup.string().required("Required"),
      coApplicantEmployerAddress2: Yup.string(),
      coApplicantEmployerCity: requiredString,
      coApplicantEmployerState: requiredString,
      coApplicantEmployerZip: Yup.number()
        .required("Required")
        .typeError("Must not include any special characters"),
      coApplicantEmployerPhone: phone,
      coApplicantSupervisorName: requiredString,
      coApplicantGrossSalary: Yup.number()
        .required("Required")
        .typeError("Must be a number, e.g 40000"),
      coApplicantMonthlyIncome: Yup.number()
        .required("Required")
        .typeError("Must be a number, e.g 1500"),
    }),
  });

  const Context = () => {
    // Grab values and submitForm from context
    const { errors, values, validateForm } = useFormikContext();
    useEffect(() => {
      validateForm().then((errors) =>
        !objectEmpty(errors) ? disableBtn(true) : disableBtn(false)
      );
    }, [errors]);
    return null;
  };

  return (
    <>
      <div className="container">
        {/* <Debugger data={coEmploymentInfoValues} /> */}

        <Formik initialValues={coEmploymentInfoValues} validationSchema={validationSchema}>
          <Form onChange={onChange} id="form">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label
                  className="col-form-label"
                  htmlFor="coApplicantCurrentlyEmployed"
                >
                  Is your Co-Applicant currently employed?
                </label>
                <Field
                  className="custom-select"
                  name="coApplicantCurrentlyEmployed"
                  as="select"
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Field>
                <ErrorMessage
                  component={FormError}
                  name="coApplicantCurrentlyEmployed"
                />
              </div>
            </div>

            {coEmploymentInfoValues.coApplicantCurrentlyEmployed === "Yes" && (
              <>
                <div className="form-row">
                  <div className="form-group col-lg-6 col-md-8">
                    <label htmlFor="coApplicantCurrentEmployer">
                      Current Employer
                    </label>
                    <Field
                      name="coApplicantCurrentEmployer"
                      className="form-control"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantCurrentEmployer"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-6 col-md-8">
                    <label htmlFor="coApplicantCurrentPosition">
                      Current Position
                    </label>
                    <Field
                      name="coApplicantCurrentPosition"
                      className="form-control"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantCurrentPosition"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="coApplicantEmployedSince">
                      Employed Since
                    </label>
                    <Field
                      type="date"
                      className="form-control"
                      name="coApplicantEmployedSince"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantEmployedSince"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-6">
                    <label htmlFor="coApplicantEmployerAddress">
                      Employer's Street Address
                    </label>
                    <Field
                      className="form-control"
                      name="coApplicantEmployerAddress"
                      placeholder="1234 Main St"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantEmployerAddress"
                    />
                  </div>

                  <div className="form-group col-lg-4">
                    <label htmlFor="coApplicantEmployerAddress2">
                      Suite, Apartment, Unit, etc.
                    </label>
                    <Field
                      className="form-control"
                      name="coApplicantEmployerAddress2"
                      placeholder="Apartment, studio, or floor"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantEmployerAddress2"
                    />
                  </div>

                  <div className="form-group col-lg-4">
                    <label htmlFor="coApplicantEmployerCity">City</label>
                    <Field
                      className="form-control"
                      name="coApplicantEmployerCity"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantEmployerCity"
                    />
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="coApplicantEmployerState">State</label>
                    <Field
                      name="coApplicantEmployerState"
                      className="custom-select"
                      as="select"
                    >
                      <option value="">Select...</option>
                      {renderStates()}
                    </Field>
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantEmployerState"
                    />
                  </div>

                  <div className="form-group col-lg-2 col-md-4">
                    <label htmlFor="coApplicantEmployerZip">Zip</label>
                    <Field
                      className="form-control"
                      name="coApplicantEmployerZip"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantEmployerZip"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="coApplicantEmployerPhone">
                      Employer's Phone Number:
                    </label>
                    <Field
                      className="form-control"
                      name="coApplicantEmployerPhone"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantEmployerPhone"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-6 col-md-6">
                    <label htmlFor="coApplicantSupervisorName">
                      Current manager or supervisor's name
                    </label>
                    <Field
                      name="coApplicantSupervisorName"
                      className="form-control"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantSupervisorName"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-6 col-md-6">
                    <label htmlFor="coApplicantPreviousEmployer">
                      Previous employer if less than 2 years ago
                    </label>
                    <Field
                      name="coApplicantPreviousEmployer"
                      className="form-control"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantPreviousEmployer"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-6 col-md-6">
                    <label htmlFor="coApplicantPreviousPosition">
                      Previous Position
                    </label>
                    <Field
                      name="coApplicantPreviousPosition"
                      className="form-control"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantPreviousPosition"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="coApplicantGrossSalary">
                      Gross annual Income
                    </label>
                    <Field
                      name="coApplicantGrossSalary"
                      className="form-control"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantGrossSalary"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="coApplicantMonthlyIncome">
                      Net monthly income
                    </label>
                    <Field
                      name="coApplicantMonthlyIncome"
                      className="form-control"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="coApplicantMonthlyIncome"
                    />
                  </div>
                </div>
              </>
            )}
            <Context />
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default CoApplicantEmployment;
