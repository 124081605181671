import React, { useEffect } from "react";
import { Form, Field, Formik, useFormikContext, ErrorMessage } from "formik";
// import Debugger from "../utils/Debugger";
import { objectEmpty, requiredString, dobLimit } from "../../utils/helpers";
import * as Yup from "yup";
import FormError from "../../utils/FormError";

const LoanApplicationSignatures = ({
  disableBtn,
  initialValues,
  setInitialValues,
  signatureOrder,
}) => {
  const { loanSignatureValues, applicantStatus } = initialValues;

  const onChange = (e) => {
    let data;
    if (e.target.name === "loanAgreementCheck") {
      data = {
        [e.target.name]: e.target.checked,
      };
    } else {
      data = {
        [e.target.name]: e.target.value,
      };
    }

    setInitialValues({
      ...initialValues,
      loanSignatureValues: {
        ...initialValues.loanSignatureValues,
        ...data,
      },
    });
  };

  const validationSchema = Yup.object({
    signatureName: requiredString,
    agreeDate1: Yup.date().required("Required"),
    ...(applicantStatus.hasCoApplicant === "Yes" && {
      coSignatureName: requiredString.notOneOf(
        [loanSignatureValues.signatureName],
        "Signees must not be the same person."
      ),
      agreeDate2: Yup.date().required("Required"),
    }),
  });

  const Context = () => {
    const { values, errors } = useFormikContext();
    useEffect(() => {
      (signatureOrder === 1 && !values.agreeDate1) || !objectEmpty(errors)
        ? disableBtn(true)
        : (signatureOrder === 2 && !loanSignatureValues.loanAgreementCheck) ||
          !objectEmpty(errors)
        ? disableBtn(true)
        : disableBtn(false);
    }, [values, errors]);
    return null;
  };

  return (
    <>
      {/* <Debugger data={loanSignatureValues} /> */}
      <Formik initialValues={loanSignatureValues} validationSchema={validationSchema}>
        <Form onChange={onChange}>
          <div className="container">
            <div>
              {signatureOrder === 1 ? (
                <div>
                  <p>
                    I hereby certify that the information provided is true,
                    correct, and complete. I authorize Muslims For Change, Inc.
                    (MFC) to investigate my credit, credential and/or employment
                    history and authorize MFC to make inquiries and gather
                    information that it feels is necessary concerning statements
                    made on this application. It is further agreed that MFC will
                    be promptly notified of any material changes in this
                    information.
                  </p>

                  <h4>LIMITATION OF LIABILITY</h4>

                  <p>
                    Borrower understands and agrees that Muslims For Change,
                    Inc. (MFC) provides interest free loans to qualified members
                    of the Muslim community. Borrower further understands and
                    agrees that MFC’s sole mission and purpose is to provide
                    assistance to qualified individuals, and while MFC may offer
                    referrals to third parties service providers, MFC does not
                    engage in or offer any clinical or non-financial counseling
                    services. Borrower agrees that MFC will not under any
                    circumstances be held liable for any damages whatsoever
                    arising out of or relating to its financial services or any
                    referrals it may provide, including, without limitation, any
                    special, indirect, incidental, consequential or exemplary
                    damages. Borrower agrees to indemnify and hold harmless MFC
                    and its officers, directors, employees, agents or volunteers
                    from any and all claims, injuries, damages, losses or suits
                    (including reasonable attorney fees), arising out of or in
                    connection with the performance of its services.
                  </p>
                </div>
              ) : (
                <div>
                  <p>
                    Muslims For Change, Inc. (MFC) is reviewing a loan
                    application from the Borrower for an interest free loan in
                    the sum of the Loan Amount. If the loan is approved, I agree
                    to fully, absolutely and unconditionally guarantee full
                    payment of the Loan Amount. I also agree that a creditor can
                    collect this debt from me and that I will pay any collection
                    costs if a creditor gets involved, which can increase the
                    initial borrowed amount.
                  </p>

                  <p>
                    All persons signing the Loan Agreement for this loan are
                    jointly and severally liable for payment of the Loan Amount.
                    This means that each Applicant/Co-Applicant is liable for
                    the full amount and, as such, could be called upon to repay
                    the total balance due.
                  </p>

                  <p>
                    I recognize that this Loan Agreement creates a binding
                    obligation on me until the Loan Amount is fully paid. I
                    understand that typical loan repayment terms for the
                    Borrower are 80 to 120 equal monthly payments depending upon
                    the size of the loan, beginning from one months after the
                    Borrower receives the loan and signs the loan Promissory
                    Note. The actual repayment terms for the Borrower may vary
                    somewhat from these typical terms. I recognize that defaults
                    may be reported to appropriate credit rating and collection
                    agencies.
                  </p>

                  <p>
                    Borrower understands and agrees that any and all disputes
                    which occur as a result of this loan and which are unable to
                    be resolved between the parties directly shall be subject to
                    binding arbitration by an MFC appointed Judge (Qadi). Should any
                    party fail to abide by the rulings of the Judge (Qadi), the other
                    party may pursue enforcement of the ruling to the degree
                    allowed by the Judge (Qadi) or MFC’s Sharia authorities. All parties
                    to any loan and or agreement agree to be bound by any and
                    all rulings of the appointed Judge (Qadi).
                  </p>

                  <p>
                    All matters affecting the interpretation of this Agreement
                    and the rights of the parties hereto shall be governed by
                    the laws of the State of Georgia.
                  </p>
                </div>
              )}
            </div>

            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="signatureName">Applicant Full Name</label>
                <Field
                  className="form-control"
                  name="signatureName"
                  placeholder="Applicant Name"
                />
                <ErrorMessage component={FormError} name="signatureName" />
              </div>

              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="agreeDate1">Date</label>
                <Field type="date" className="form-control" name="agreeDate1" />
                <ErrorMessage component={FormError} name="agreeDate1" />
              </div>
            </div>

            {applicantStatus.hasCoApplicant === "Yes" && (
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label htmlFor="coSignatureName">
                    Co-Applicant Full Name
                  </label>
                  <Field
                    className="form-control"
                    name="coSignatureName"
                    placeholder="Co-Applicant Name"
                  />
                  <ErrorMessage component={FormError} name="coSignatureName" />
                </div>

                <div className="form-group col-md-6 col-lg-4">
                  <label htmlFor="agreeDate2">Date</label>
                  <Field
                    type="date"
                    className="form-control"
                    name="agreeDate2"
                  />
                  <ErrorMessage component={FormError} name="agreeDate2" />
                </div>
              </div>
            )}

            {signatureOrder === 2 && (
              <div className="form-check">
                <Field
                  name="loanAgreementCheck"
                  type="checkbox"
                  className="form-check-input"
                />
                <label htmlFor="loanAgreementCheck">
                  I have read this Agreement and I/we understand and fully agree
                  to it.
                </label>
              </div>
            )}
          </div>
          <Context />
        </Form>
      </Formik>
    </>
  );
};

export default LoanApplicationSignatures;
