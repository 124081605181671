import React, { useEffect } from "react";
import { Form, Field, Formik, ErrorMessage, useFormikContext } from "formik";
import {
  renderStates,
  objectEmpty,
  phone,
  requiredString,
  dobLimit,
} from "../../utils/helpers";
import * as Yup from "yup";
// import Debugger from "../utils/Debugger";
import FormError from "../../utils/FormError";
import { employmentInfoValues as initialData } from "../../consts/formValues";

const ApplicantEmployment = ({ disableBtn, initialValues, setInitialValues }) => {
  const { employmentInfoValues } = initialValues;

  useEffect(() => {
    if (employmentInfoValues.currentlyEmployed !== "Yes") {
      document.getElementById("form").reset();
    }
    return;
  }, [employmentInfoValues.currentlyEmployed]);

  const onChange = (e) => {
    setInitialValues({
      ...initialValues,
      employmentInfoValues: {
        ...initialValues.employmentInfoValues,
        [e.target.name]: e.target.value,
      },
    });

    if (e.target.name === "currentlyEmployed" && e.target.value !== "Yes") {
      setInitialValues({
        ...initialValues,
        employmentInfoValues: {
          ...initialValues.employmentInfoValues,
          ...initialData,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const validationSchema = Yup.object({
    currentlyEmployed: requiredString,
    ...(employmentInfoValues.currentlyEmployed === "Yes" && {
      currentEmployer: requiredString,
      currentPosition: requiredString,
      employedSince: Yup.date().required("Required"),
      employerAddress: Yup.string().required("Required"),
      employerAddress2: Yup.string(),
      employerCity: requiredString,
      employerState: requiredString,
      employerZip: Yup.number()
        .required("Required")
        .typeError("Must not include any special characters"),
      employerPhone: phone,
      supervisorName: requiredString,
      grossSalary: Yup.number()
        .required("Required")
        .typeError("Must be a number, e.g 40000"),
      monthlyIncome: Yup.number()
        .required("Required")
        .typeError("Must be a number, e.g 1500"),
    }),
  });

  const Context = () => {
    // Grab values and submitForm from context
    const { errors, values, validateForm } = useFormikContext();
    useEffect(() => {
      validateForm().then((errors) =>
        !objectEmpty(errors) ? disableBtn(true) : disableBtn(false)
      );
    }, [errors]);
    return null;
  };

  return (
    <>
      <div className="container">
        {/* <Debugger data={employmentInfoValues} /> */}

        <Formik initialValues={employmentInfoValues} validationSchema={validationSchema}>
          <Form onChange={onChange} id="form">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="col-form-label" htmlFor="currentlyEmployed">
                  Are you currently employed?
                </label>
                <Field
                  className="custom-select"
                  name="currentlyEmployed"
                  as="select"
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Field>
                <ErrorMessage component={FormError} name="currentlyEmployed" />
              </div>
            </div>

            {employmentInfoValues.currentlyEmployed === "Yes" && (
              <>
                <div className="form-row">
                  <div className="form-group col-lg-6 col-md-8">
                    <label htmlFor="currentEmployer">Current Employer</label>
                    <Field name="currentEmployer" className="form-control" />
                    <ErrorMessage
                      component={FormError}
                      name="currentEmployer"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-6 col-md-8">
                    <label htmlFor="currentPosition">Current Position</label>
                    <Field name="currentPosition" className="form-control" />
                    <ErrorMessage
                      component={FormError}
                      name="currentPosition"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="employedSince">Employed Since</label>
                    <Field
                      type="date"
                      className="form-control"
                      name="employedSince"
                    />
                    <ErrorMessage component={FormError} name="employedSince" />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-6">
                    <label htmlFor="employerAddress">
                      Employer's Street Address
                    </label>
                    <Field
                      className="form-control"
                      name="employerAddress"
                      placeholder="1234 Main St"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="employerAddress"
                    />
                  </div>

                  <div className="form-group col-lg-4">
                    <label htmlFor="employerAddress2">
                      Suite, Apartment, Unit, etc.
                    </label>
                    <Field
                      className="form-control"
                      name="employerAddress2"
                      placeholder="Apartment, studio, or floor"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="employerAddress2"
                    />
                  </div>

                  <div className="form-group col-lg-4">
                    <label htmlFor="employerCity">City</label>
                    <Field className="form-control" name="employerCity" />
                    <ErrorMessage component={FormError} name="employerCity" />
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="employerState">State</label>
                    <Field
                      name="employerState"
                      className="custom-select"
                      as="select"
                    >
                      <option value="">Select...</option>
                      {renderStates()}
                    </Field>
                    <ErrorMessage component={FormError} name="employerState" />
                  </div>

                  <div className="form-group col-lg-2 col-md-4">
                    <label htmlFor="employerZip">Zip</label>
                    <Field className="form-control" name="employerZip" />
                    <ErrorMessage component={FormError} name="employerZip" />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="employerPhone">
                      Employer's Phone Number:
                    </label>
                    <Field className="form-control" name="employerPhone" />
                    <ErrorMessage component={FormError} name="employerPhone" />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-6 col-md-6">
                    <label htmlFor="supervisorName">
                      Current manager or supervisor's name
                    </label>
                    <Field name="supervisorName" className="form-control" />
                    <ErrorMessage component={FormError} name="supervisorName" />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-6 col-md-6">
                    <label htmlFor="previousEmployer">
                      Previous employer if less than 2 years ago
                    </label>
                    <Field name="previousEmployer" className="form-control" />
                    <ErrorMessage
                      component={FormError}
                      name="previousEmployer"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-6 col-md-6">
                    <label htmlFor="previousPosition">Previous Position</label>
                    <Field name="previousPosition" className="form-control" />
                    <ErrorMessage
                      component={FormError}
                      name="previousPosition"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="grossSalary">Gross annual Income</label>
                    <Field name="grossSalary" className="form-control" />
                    <ErrorMessage component={FormError} name="grossSalary" />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="monthlyIncome">Net monthly income</label>
                    <Field name="monthlyIncome" className="form-control" />
                    <ErrorMessage component={FormError} name="monthlyIncome" />
                  </div>
                </div>
              </>
            )}
            <Context />
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default ApplicantEmployment;
