import React from "react";
import { css } from "emotion";

const BigBanner = ({ children: bannerContent, btnText, img, path }) => {
  const styles = css`
    & {
      .big-banner-content {
        max-width: 70%;
        margin: 0 auto;
        text-align: center;
      }
      .big-banner {
        position: relative;
        z-index: 99;
        padding: 115px 0;
        background-size: cover !important;
        background: url(${img}) center no-repeat;
      }
      .big-banner h1,
      .big-banner p {
        color: white;
        font-size: 4em;
        font-weight: bold;
      }
      .big-banner p {
        font-size: 1.5em;
        font-weight: 400;
        max-width: 840px;
        margin: 20px auto;
      }
      .big-banner::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
      }

      @media screen and (max-width: 927px) {
        .big-banner-content {
          max-width: 90%;
        }
        .big-banner {
          padding: 60px 0;
        }
        .big-banner h1 {
          font-size: 2em;
        }
        .big-banner p {
          font-size: 0.9em;
          font-weight: 500;
          max-width: 80%;
        }
      }
      @media screen and (max-width: 500px) {
        .big-banner p {
          max-width: 100%;
        }
      }
    }
  `;

  return (
    <div className={styles}>
      <div className="big-banner">
        <div className="big-banner-content">
          {bannerContent}
          <div className="big-banner-btn">
            <a href={path} className="btn gradient-bg">
              {btnText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigBanner;
