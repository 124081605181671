import React, { useEffect } from "react";
import { Form, Field, Formik, ErrorMessage, useFormikContext } from "formik";
import { objectEmpty, expenseValue } from "../../utils/helpers";
import * as Yup from "yup";
// import Debugger from "../utils/Debugger";
import FormError from "../../utils/FormError";

const CoApplicantExpenses = ({
  disableBtn,
  initialValues,
  setInitialValues,
}) => {
  const { coApplicantExpenseValues, coApplicantInfoValues } = initialValues;

  const onChange = (e) => {
    setInitialValues({
      ...initialValues,
      coApplicantExpenseValues: {
        ...initialValues.coApplicantExpenseValues,
        [e.target.name]: e.target.value,
      },
    });
  };

  const validationSchema = Yup.object({
    coApplicantMortgageOrRent:
      coApplicantInfoValues.coApplicantLiving === "Renting" ||
      coApplicantInfoValues.coApplicantLiving === "Mortgage"
        ? expenseValue.notOneOf([0], "Must not be 0")
        : expenseValue,
    coApplicantHOA: expenseValue,
    coApplicantCableOrInternet: expenseValue,
    coApplicantHomePhone: expenseValue,
    coApplicantCellPhone: expenseValue,
    coApplicantHomeUtilities: expenseValue,
    coApplicantGroceries: expenseValue,
    coApplicantOuting: expenseValue,
    coApplicantCarLoanOrLease: expenseValue,
    coApplicantCarMaintenance: expenseValue,
    coApplicantCarInsurance: expenseValue,
    coApplicantHomeInsurance: expenseValue,
    coApplicantHealthInsurance: expenseValue,
    coApplicantPocketMedicalExpenses: expenseValue,
    coApplicantTotalCreditCardDebt: expenseValue,
    coApplicantCreditCardPayment: expenseValue,
    coApplicantStudentLoansPayment: expenseValue,
    coApplicantTuitionForKids: expenseValue,
    coApplicantChildSupportOrAlimony: expenseValue,
    coApplicantOtherExpenses: Yup.string(),
  });

  const Context = () => {
    // Grab values and submitForm from context
    const { errors, validateForm } = useFormikContext();
    useEffect(() => {
      validateForm().then((errors) =>
        !objectEmpty(errors) ? disableBtn(true) : disableBtn(false)
      );
    }, [errors]);
    return null;
  };

  return (
    <>
      <div className="container">
        {/* <Debugger data={applicantExpenseValues} /> */}
        <Formik
          initialValues={coApplicantExpenseValues}
          validationSchema={validationSchema}
        >
          {({ errors, touched }) => (
            <Form onChange={onChange}>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantMortgageOrRent">Mortgage/Rent</label>
                  <Field className="form-control" name="coApplicantMortgageOrRent" />
                  {errors.coApplicantMortgageOrRent && (
                    <FormError>{errors.coApplicantMortgageOrRent}</FormError>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantHOA">HOA (Home Owner's Association)</label>
                  <Field className="form-control" name="coApplicantHOA" />
                  <ErrorMessage component={FormError} name="coApplicantHOA" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantCableOrInternet">Cable/Internet</label>
                  <Field className="form-control" name="coApplicantCableOrInternet" />
                  <ErrorMessage component={FormError} name="coApplicantCableOrInternet" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantHomePhone">Home Phone</label>
                  <Field className="form-control" name="coApplicantHomePhone" />
                  <ErrorMessage component={FormError} name="coApplicantHomePhone" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantCellPhone">Cell Phone</label>
                  <Field className="form-control" name="coApplicantCellPhone" />
                  <ErrorMessage component={FormError} name="coApplicantCellPhone" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantHomeUtilities">Electricity/Gas/Water</label>
                  <Field className="form-control" name="coApplicantHomeUtilities" />
                  <ErrorMessage component={FormError} name="coApplicantHomeUtilities" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantGroceries">Groceries</label>
                  <Field className="form-control" name="coApplicantGroceries" />
                  <ErrorMessage component={FormError} name="coApplicantGroceries" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantOuting">Dinning out/Entertainment</label>
                  <Field className="form-control" name="coApplicantOuting" />
                  <ErrorMessage component={FormError} name="coApplicantOuting" />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantCarLoanOrLease">Car Loan/Lease</label>
                  <Field className="form-control" name="coApplicantCarLoanOrLease" />
                  <ErrorMessage component={FormError} name="coApplicantCarLoanOrLease" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantCarMaintenance">Car Maintenance </label>
                  <Field className="form-control" name="coApplicantCarMaintenance" />
                  <ErrorMessage component={FormError} name="coApplicantCarMaintenance" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantCarInsurance">Car Insurance </label>
                  <Field className="form-control" name="coApplicantCarInsurance" />
                  <ErrorMessage component={FormError} name="coApplicantCarInsurance" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantHomeInsurance">Home Insurance </label>
                  <Field className="form-control" name="coApplicantHomeInsurance" />
                  <ErrorMessage component={FormError} name="coApplicantHomeInsurance" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantHealthInsurance">Health Insurance </label>
                  <Field className="form-control" name="coApplicantHealthInsurance" />
                  <ErrorMessage component={FormError} name="coApplicantHealthInsurance" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantPocketMedicalExpenses">
                    Out of pocket medical expenses
                  </label>
                  <Field
                    className="form-control"
                    name="coApplicantPocketMedicalExpenses"
                  />
                  <ErrorMessage
                    component={FormError}
                    name="coApplicantPocketMedicalExpenses"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantTotalCreditCardDebt">
                    Total Credit card debt
                  </label>
                  <Field className="form-control" name="coApplicantTotalCreditCardDebt" />
                  <ErrorMessage
                    component={FormError}
                    name="coApplicantTotalCreditCardDebt"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantCreditCardPayment">
                    Credit card monthly payment
                  </label>
                  <Field className="form-control" name="coApplicantCreditCardPayment" />
                  <ErrorMessage
                    component={FormError}
                    name="coApplicantCreditCardPayment"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantStudentLoansPayment">
                    Student Loans Payment
                  </label>
                  <Field className="form-control" name="coApplicantStudentLoansPayment" />
                  <ErrorMessage
                    component={FormError}
                    name="coApplicantStudentLoansPayment"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantTuitionForKids">
                    Tuition for all children combined if any
                  </label>
                  <Field className="form-control" name="coApplicantTuitionForKids" />
                  <ErrorMessage component={FormError} name="coApplicantTuitionForKids" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantChildSupportOrAlimony">
                    Child Support/Alimony
                  </label>
                  <Field
                    className="form-control"
                    name="coApplicantChildSupportOrAlimony"
                  />
                  <ErrorMessage
                    component={FormError}
                    name="coApplicantChildSupportOrAlimony"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="coApplicantOtherExpenses">
                    Other expenses, please describe
                  </label>
                  <Field
                    className="form-control"
                    name="coApplicantOtherExpenses"
                    as="textarea"
                  />
                  <ErrorMessage component={FormError} name="coApplicantOtherExpenses" />
                </div>
              </div>
              <Context />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CoApplicantExpenses;
