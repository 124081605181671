import React, { useEffect } from "react";
import { Form, Field, Formik, ErrorMessage, useFormikContext } from "formik";
import { objectEmpty, requiredString } from "../../utils/helpers";
import * as Yup from "yup";
// import Debugger from "../../utils/Debugger";
import FormError from "../../utils/FormError";
import { coApplicantInfoValues as initialData } from "../../consts/formValues";

const CoApplicant = ({
  disableBtn,
  initialValues: { applicantStatus },
  setInitialValues,
}) => {
  // const { applicantStatus } = initialValues;
  useEffect(() => {
    if (applicantStatus.maritalStatus !== "Yes") {
      setInitialValues((state) => {
        return {
          ...state,
          applicantStatus: {
            ...state.applicantStatus,
            wifeIsCoApplicant: "",
          },
        };
      });
    } else if (applicantStatus.hasCoApplicant !== "Yes") {
      setInitialValues((state) => {
        return {
          ...state,
          coApplicantInfoValues: {
            ...initialData,
          },
          applicantStatus: {
            ...state.applicantStatus,
            coApplicantLivesTogether: "",
            wifeIsCoApplicant: "",
          },
        };
      });
    }
  }, [
    applicantStatus.maritalStatus !== "Yes" ||
      applicantStatus.hasCoApplicant !== "Yes",
  ]);

  const onChange = (e) => {
    setInitialValues((state) => {
      return {
        ...state,
        applicantStatus: {
          ...state.applicantStatus,
          [e.target.name]: e.target.value,
        },
      };
    });
  };

  const validationSchema = Yup.object({
    maritalStatus: requiredString,
    hasCoApplicant: requiredString,
    coApplicantLivesTogether: Yup.string().when("hasCoApplicant", {
      is: "Yes",
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    wifeIsCoApplicant: Yup.string().when(["maritalStatus", "hasCoApplicant"], {
      is: (a, b) => a === "Yes" && b === "Yes",
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
  });

  const Context = () => {
    // Grab values and submitForm from context
    const { validateForm } = useFormikContext();
    useEffect(() => {
      validateForm(applicantStatus).then((errors) =>
        !objectEmpty(errors) ? disableBtn(true) : disableBtn(false)
      );
    }, [applicantStatus]);
    return null;
  };

  return (
    <>
      <div className="container">
        {/* <Debugger data={applicantStatus} /> */}

        <Formik
          initialValues={applicantStatus}
          validationSchema={validationSchema}
        >
          <Form onChange={onChange} id="form">
            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4 col-lg-4">
                <label className="col-form-label" htmlFor="maritalStatus">
                  Are you married?
                </label>
                <Field
                  className="custom-select"
                  name="maritalStatus"
                  as="select"
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Field>
                <ErrorMessage component={FormError} name="maritalStatus" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4 col-lg-4">
                <label className="col-form-label" htmlFor="hasCoApplicant">
                  Do you have a Co-Applicant?
                </label>
                <Field
                  className="custom-select"
                  name="hasCoApplicant"
                  as="select"
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Field>
                <ErrorMessage component={FormError} name="hasCoApplicant" />
              </div>
            </div>

            {applicantStatus.hasCoApplicant === "Yes" && (
              <div className="form-row">
                <div className="form-group col-md-6 col-lg-4">
                  <label
                    className="col-form-label"
                    htmlFor="coApplicantLivesTogether"
                  >
                    Does your Co-Applicant live with you?
                  </label>
                  <Field
                    className="custom-select"
                    name="coApplicantLivesTogether"
                    value={applicantStatus.coApplicantLivesTogether}
                    as="select"
                  >
                    <option value="">Select...</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Field>
                  <ErrorMessage
                    component={FormError}
                    name="coApplicantLivesTogether"
                  />
                </div>
              </div>
            )}

            {applicantStatus.maritalStatus === "Yes" &&
              applicantStatus.hasCoApplicant === "Yes" && (
                <div className="form-row">
                  <div className="form-group col-md-6 col-lg-4 col-lg-4">
                    <label className="col-form-label">
                      Is your spouse your Co-Applicant?
                    </label>
                    <Field
                      id="wifeIsCoApplicant"
                      className="custom-select"
                      name="wifeIsCoApplicant"
                      value={applicantStatus.wifeIsCoApplicant}
                      as="select"
                    >
                      <option value="">Select...</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Field>
                    <ErrorMessage
                      component={FormError}
                      name="wifeIsCoApplicant"
                    />
                  </div>
                </div>
              )}
            <Context />
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default CoApplicant;
