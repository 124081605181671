import React, { useEffect } from "react";
import { Form, Field, Formik, useFormikContext } from "formik";
// import Debugger from "../utils/Debugger";

const LenderApplicationExceptions = ({
  disableBtn,
  initialValues,
  setInitialValues,
}) => {
  const onChange = (e) => {
    setInitialValues({
      ...initialValues,
      [e.target.name]: e.target.checked,
    });
  };

  const Context = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      !values.initialAgreement ? disableBtn(true) : disableBtn(false);
    }, [values]);
    return null;
  };

  return (
    <>
      {/* <Debugger data={agreement} /> */}

      <Formik initialValues={initialValues}>
        <Form onChange={onChange}>
          <div>
            <h4 className="ml-3">In order to lend MFC the lender must be:</h4>
            <ol>
              <li>The applicant must be Muslim.</li>
              <li>Is at least eighteen (18) years of age.</li>
              <li>Able to wait for at least 3 years for repayment.</li>
              <li>Comfortable providing a 6 month notice prior to repayment.</li>
              <li>Is a U.S. citizen or legal resident.</li>
              <li>Can lend a minimum of $2,000.</li>
            </ol>

            <div className="form-check">
              <Field
                name="initialAgreement"
                type="checkbox"
                className="form-check-input"
              />
              <label htmlFor="initialAgreement">
                I have reviewed and understand the criteria for lending MFC.
              </label>
            </div>
          </div>
          <Context />
        </Form>
      </Formik>
    </>
  );
};

export default LenderApplicationExceptions;
