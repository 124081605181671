import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer';
const FieldGroup = ({ values, extraSpace }) => {
  const renderValues = () => {
    // const spaceNeeded = extraSpace ? styles.extraSpace : styles.text
    const spaceNeeded = styles.text
    return values.map(({ name, value, check, condition }) => {
      // if (condition) {
      //   if (check === condition) {
      //     return <Text key={name} style={spaceNeeded}>
      //       <Text style={styles.label}>{name}</Text>
      //       &nbsp;&nbsp;&nbsp;{value}
      //     </Text>
      //   }
      // } else {
      //   return <Text key={name} style={spaceNeeded}>
      //     <Text style={styles.label}>{name}</Text>
      //     &nbsp;&nbsp;&nbsp;{value}
      //   </Text>
      // }
      return <Text key={name} style={spaceNeeded}>
          <Text style={styles.label}>{name}</Text>
          &nbsp;&nbsp;&nbsp;{value}
        </Text>
    })
  }

  return (
    <View style={styles.fieldGroup}>
      {renderValues()}
    </View>
  )
}

const styles = StyleSheet.create({
  label: {
    fontSize: 10
  },
  text: {
    margin: "2px 0",
    fontSize: 12,
    flex: 1
  },
  fieldGroup: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    width: "100%",
    borderBottomStyle: "solid",
    borderBottomColor: "rgb(189, 189, 189)",
    borderBottomWidth: "1px",
  },
  extraSpace: {
    margin: "2px 0",
    fontSize: 12,
    flexBasis: "20%",
    flexGrow: 1,
  }
});
export default FieldGroup
