import React, { useEffect } from "react";
import { Form, Field, Formik, ErrorMessage, useFormikContext } from "formik";
import { objectEmpty, expenseValue } from "../../utils/helpers";
import * as Yup from "yup";
// import Debugger from "../utils/Debugger";
import FormError from "../../utils/FormError";

const MonthlyExpenses = ({ disableBtn, initialValues, setInitialValues }) => {
  const { applicantExpenseValues, applicantInfoValues } = initialValues;

  const onChange = (e) => {
    setInitialValues({
      ...initialValues,
      applicantExpenseValues: {
        ...initialValues.applicantExpenseValues,
        [e.target.name]: e.target.value,
      },
    });
  };

  const validationSchema = Yup.object({
    mortgageOrRent:
      applicantInfoValues.living === "Renting" || applicantInfoValues.living === "Mortgage"
        ? expenseValue.notOneOf([0], "Must not be 0")
        : expenseValue,
    HOA: expenseValue,
    cableOrInternet: expenseValue,
    homePhone: expenseValue,
    cellPhone: expenseValue,
    homeUtilities: expenseValue,
    groceries: expenseValue,
    outing: expenseValue,
    carLoanOrLease: expenseValue,
    carMaintenance: expenseValue,
    carInsurance: expenseValue,
    homeInsurance: expenseValue,
    healthInsurance: expenseValue,
    pocketMedicalExpenses: expenseValue,
    totalCreditCardDebt: expenseValue,
    creditCardPayment: expenseValue,
    studentLoansPayment: expenseValue,
    tuitionForKids: expenseValue,
    childSupportOrAlimony: expenseValue,
    otherExpenses: Yup.string(),
  });

  const Context = () => {
    // Grab values and submitForm from context
    const { errors, values, validateForm } = useFormikContext();
    useEffect(() => {
      validateForm().then((errors) =>
        !objectEmpty(errors) ? disableBtn(true) : disableBtn(false)
      );
    }, [errors]);
    return null;
  };

  return (
    <>
      <div className="container">
        {/* <Debugger data={applicantExpenseValues} /> */}
        <Formik
          initialValues={applicantExpenseValues}
          validationSchema={validationSchema}
        >
          {({ errors, touched }) => (
            <Form onChange={onChange}>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="mortgageOrRent">Mortgage/Rent</label>
                  <Field className="form-control" name="mortgageOrRent" />
                  {errors.mortgageOrRent && <FormError>{errors.mortgageOrRent}</FormError>}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="HOA">HOA (Home Owner's Association)</label>
                  <Field className="form-control" name="HOA" />
                  <ErrorMessage component={FormError} name="HOA" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="cableOrInternet">Cable/Internet</label>
                  <Field className="form-control" name="cableOrInternet" />
                  <ErrorMessage component={FormError} name="cableOrInternet" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="homePhone">Home Phone</label>
                  <Field className="form-control" name="homePhone" />
                  <ErrorMessage component={FormError} name="homePhone" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="cellPhone">Cell Phone</label>
                  <Field className="form-control" name="cellPhone" />
                  <ErrorMessage component={FormError} name="cellPhone" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="homeUtilities">Electricity/Gas/Water</label>
                  <Field className="form-control" name="homeUtilities" />
                  <ErrorMessage component={FormError} name="homeUtilities" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="groceries">Groceries</label>
                  <Field className="form-control" name="groceries" />
                  <ErrorMessage component={FormError} name="groceries" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="outing">Dinning out/Entertainment</label>
                  <Field className="form-control" name="outing" />
                  <ErrorMessage component={FormError} name="outing" />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="carLoanOrLease">Car Loan/Lease</label>
                  <Field className="form-control" name="carLoanOrLease" />
                  <ErrorMessage component={FormError} name="carLoanOrLease" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="carMaintenance">Car Maintenance </label>
                  <Field className="form-control" name="carMaintenance" />
                  <ErrorMessage component={FormError} name="carMaintenance" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="carInsurance">Car Insurance </label>
                  <Field className="form-control" name="carInsurance" />
                  <ErrorMessage component={FormError} name="carInsurance" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="homeInsurance">Home Insurance </label>
                  <Field className="form-control" name="homeInsurance" />
                  <ErrorMessage component={FormError} name="homeInsurance" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="healthInsurance">Health Insurance </label>
                  <Field className="form-control" name="healthInsurance" />
                  <ErrorMessage component={FormError} name="healthInsurance" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="pocketMedicalExpenses">
                    Out of pocket medical expenses
                  </label>
                  <Field
                    className="form-control"
                    name="pocketMedicalExpenses"
                  />
                  <ErrorMessage
                    component={FormError}
                    name="pocketMedicalExpenses"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="totalCreditCardDebt">
                    Total Credit card debt
                  </label>
                  <Field className="form-control" name="totalCreditCardDebt" />
                  <ErrorMessage
                    component={FormError}
                    name="totalCreditCardDebt"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="creditCardPayment">
                    Credit card monthly payment
                  </label>
                  <Field className="form-control" name="creditCardPayment" />
                  <ErrorMessage
                    component={FormError}
                    name="creditCardPayment"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="studentLoansPayment">
                    Student Loans Payment
                  </label>
                  <Field className="form-control" name="studentLoansPayment" />
                  <ErrorMessage
                    component={FormError}
                    name="studentLoansPayment"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="tuitionForKids">
                    Tuition for all children combined if any
                  </label>
                  <Field className="form-control" name="tuitionForKids" />
                  <ErrorMessage component={FormError} name="tuitionForKids" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="childSupportOrAlimony">
                    Child Support/Alimony
                  </label>
                  <Field
                    className="form-control"
                    name="childSupportOrAlimony"
                  />
                  <ErrorMessage
                    component={FormError}
                    name="childSupportOrAlimony"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="otherExpenses">
                    Other expenses, please describe
                  </label>
                  <Field
                    className="form-control"
                    name="otherExpenses"
                    as="textarea"
                  />
                  <ErrorMessage component={FormError} name="otherExpenses" />
                </div>
              </div>
              <Context />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default MonthlyExpenses;
