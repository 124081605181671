import React, { useEffect } from "react";
import { Form, Field, Formik, ErrorMessage, useFormikContext } from "formik";
import { objectEmpty, requiredString } from "../../utils/helpers";
import * as Yup from "yup";
// import Debugger from "../utils/Debugger";
import FormError from "../../utils/FormError";

const LoanRequest = ({ disableBtn, initialValues, setInitialValues }) => {
  const { loanInfoValues } = initialValues;

  const onChange = (e) => {
    setInitialValues({
      ...initialValues,
      loanInfoValues: {
        ...initialValues.loanInfoValues,
        [e.target.name]: e.target.value,
      },
    });
  };

  const validationSchema = Yup.object({
    loanRequested: Yup.number()
      .required("Required")
      .typeError("Must be a number, e.g 200000"),
    loanPurpose: Yup.string().required("Required"),
    loanImpact: Yup.string().required("Required"),
    appliedForOtherLoan: Yup.string(),
    downpayment: Yup.number()
      .required("Required")
      .typeError("Must be a number, e.g 200000"),
    preferedHomeType: requiredString,
    stayingInSameState: requiredString,
    relocateWithinState: requiredString,
  });


  const Context = () => {
    // Grab values and submitForm from context
    const { errors, values, validateForm } = useFormikContext();
    useEffect(() => {
      validateForm().then((errors) =>
        !objectEmpty(errors) ? disableBtn(true) : disableBtn(false)
      );
    }, [errors]);
    return null;
  };

  return (
    <>
      <div className="container">
        {/* <Debugger data={loanInfoValues} /> */}
        <Formik initialValues={loanInfoValues} validationSchema={validationSchema}>
          <Form onChange={onChange}>
            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="loanRequested">Amount Requested</label>
                <Field className="form-control" name="loanRequested" />
                <ErrorMessage component={FormError} name="loanRequested" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="loanPurpose">
                  What is this loan going to be used for?
                </label>
                <Field className="form-control" name="loanPurpose" as="textarea" />
                <ErrorMessage component={FormError} name="loanPurpose" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="loanImpact">
                  Please tell us how this loan would make a difference to you
                  and/or your family's well-being and your financial situation.
                </label>
                <Field
                  className="form-control"
                  name="loanImpact"
                  as="textarea"
                />
                <ErrorMessage component={FormError} name="loanImpact" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="appliedForOtherLoan">
                  Have you applied for a loan elsewhere; If so, please explain
                </label>
                <Field
                  className="form-control"
                  name="appliedForOtherLoan"
                  as="textarea"
                />
                <ErrorMessage
                  component={FormError}
                  name="appliedForOtherLoan"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="downpayment">
                  How much down payment are you willing to put upfront?
                </label>
                <Field
                  className="form-control"
                  name="downpayment"
                />
                <ErrorMessage
                  component={FormError}
                  name="downpayment"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="preferedHomeType">
                  What type of a home do you prefer?
                </label>
                <Field className="custom-select" name="preferedHomeType" as="select">
                  <option value="">Select...</option>
                  <option value="House">House</option>
                  <option value="Townhome">Townhome</option>
                  <option value="Condominium">Condominium</option>
                  <option value="Any">Any</option>
                </Field>
                <ErrorMessage component={FormError} name="preferedHomeType" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="stayingInSameState">
                  Are you planning on staying in the same state/location for at
                  least 10 years?
                </label>
                <Field
                  className="custom-select"
                  name="stayingInSameState"
                  as="select"
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Field>
                <ErrorMessage component={FormError} name="stayingInSameState" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="relocateWithinState">
                  Are you willing to relocate within the same state?
                </label>
                <Field
                  className="custom-select"
                  name="relocateWithinState"
                  as="select"
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Field>
                <ErrorMessage
                  component={FormError}
                  name="relocateWithinState"
                />
              </div>
            </div>
            <Context />
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default LoanRequest;
