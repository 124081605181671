import React, { useEffect } from "react";
import { Form, Field, Formik, ErrorMessage, useFormikContext } from "formik";
import {
  renderStates,
  objectEmpty,
  dobLimit,
  phone,
  requiredString,
} from "../../utils/helpers";
import * as Yup from "yup";
// import Debugger from "../utils/Debugger";
import { states } from "../../consts/states";
import FormError from "../../utils/FormError";

const ApplicantInfo = ({ disableBtn, initialValues, setInitialValues }) => {
  const { applicantInfoValues } = initialValues;

  const onChange = (e) => {
    setInitialValues({
      ...initialValues,
      applicantInfoValues: {
        ...initialValues.applicantInfoValues,
        [e.target.name]: e.target.value,
      },
    });
  };

  const validationSchema = Yup.object({
    firstName: requiredString,
    lastName: requiredString,
    dob: Yup.date().required("Required"),
    citizen: requiredString,
    address: Yup.string().required("Required"),
    address2: Yup.string(),
    city: requiredString,
    state: requiredString,
    zip: Yup.number()
      .required("Required")
      .typeError("Must be a number without any special characters"),
    phone: phone,
    email: Yup.string().email().required("Required"),
    living: requiredString,
    ...(applicantInfoValues.living === "Other" && {
      otherLiving: Yup.string().required("Required"),
    }),
    filedBancrupcy: requiredString,
    planForBankrupcy: requiredString,
  });

  const Context = () => {
    // Grab values and submitForm from context
    const { errors, validateForm } = useFormikContext();
    useEffect(() => {
      validateForm().then((errors) =>
        !objectEmpty(errors) ? disableBtn(true) : disableBtn(false)
      );
    }, [errors]);
    return null;
  };

  return (
    <>
      <div className="container">
        {/* <Debugger data={applicantInfoValues} /> */}
        <Formik
          initialValues={applicantInfoValues}
          validationSchema={validationSchema}
        >
          <Form onChange={onChange}>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="firstName">First Name</label>
                <Field
                  className="form-control"
                  name="firstName"
                  placeholder="First Name"
                />
                <ErrorMessage component={FormError} name="firstName" />
              </div>

              <div className="form-group col-md-3">
                <label htmlFor="middleName">Middle Name</label>
                <Field
                  className="form-control"
                  name="middleName"
                  placeholder="Last Name"
                />
                <ErrorMessage component={FormError} name="middleName" />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="lastName">Last Name</label>
                <Field
                  className="form-control"
                  name="lastName"
                  placeholder="Last Name"
                />
                <ErrorMessage component={FormError} name="lastName" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="muslimName">Muslim Name (if different)</label>
                <Field className="form-control" name="muslimName" />
                <ErrorMessage component={FormError} name="muslimName" />
              </div>

              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="dob">Date of Birth</label>
                <Field
                  type="date"
                  className="form-control"
                  name="dob"
                  placeholder="DOB"
                  max={dobLimit()}
                />
                <ErrorMessage component={FormError} name="dob" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-lg-6">
                <label htmlFor="address">Street Address</label>
                <Field
                  className="form-control"
                  name="address"
                  placeholder="1234 Main St"
                />
                <ErrorMessage component={FormError} name="address" />
              </div>

              <div className="form-group col-lg-4">
                <label htmlFor="address2">Suite, Apartment, Unit, etc.</label>
                <Field
                  className="form-control"
                  name="address2"
                  placeholder="Apartment, studio, or floor"
                />
                <ErrorMessage component={FormError} name="address2" />
              </div>

              <div className="form-group col-lg-4">
                <label htmlFor="city">City</label>
                <Field className="form-control" name="city" />
                <ErrorMessage component={FormError} name="city" />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="state">State</label>
                <Field name="state" className="custom-select" as="select">
                  <option value="">Select...</option>
                  {renderStates(states)}
                </Field>
                <ErrorMessage component={FormError} name="state" />
              </div>

              <div className="form-group col-lg-2 col-md-4">
                <label htmlFor="zip">Zip</label>
                <Field className="form-control" name="zip" />
                <ErrorMessage component={FormError} name="zip" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="phone">Phone Number:</label>
                <Field type="tel" className="form-control" name="phone" />
                <ErrorMessage component={FormError} name="phone" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="email">Email:</label>
                <Field
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="example@email.com"
                />
                <ErrorMessage component={FormError} name="email" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="citizen">
                  Are you a United States citizen?
                </label>
                <Field className="custom-select" name="citizen" as="select">
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Field>
                <ErrorMessage component={FormError} name="citizen" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="living">Current living status</label>
                <Field className="custom-select" name="living" as="select">
                  <option value="">Select...</option>
                  <option value="Renting">Renting</option>
                  <option value="Homeowner">Outright homeowner</option>
                  <option value="On a mortgage">On a mortgage</option>
                  <option value="Other">other</option>
                </Field>
                <ErrorMessage component={FormError} name="living" />
              </div>
            </div>

            {applicantInfoValues.living === "Other" && (
              <div className="form-row">
                <div className="form-group col-md-6 col-lg-4">
                  <label htmlFor="otherLiving">Please explain</label>
                  <Field name="otherLiving" className="form-control" />
                  <ErrorMessage component={FormError} name="otherLiving" />
                </div>
              </div>
            )}

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="filedBancrupcy">
                  Have you ever filed for bankrupcy?
                </label>
                <Field
                  className="custom-select col-lg-6"
                  name="filedBancrupcy"
                  as="select"
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Field>
                <ErrorMessage component={FormError} name="filedBancrupcy" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-lg-6">
                <label htmlFor="planForBankrupcy">
                  Are you planning on filing bankrupcy in the next 12 months?
                </label>
                <Field
                  className="custom-select col-md-6"
                  name="planForBankrupcy"
                  as="select"
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Field>
                <ErrorMessage component={FormError} name="planForBankrupcy" />
              </div>
            </div>
            <Context />
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default ApplicantInfo;
