import React, { useEffect } from "react";
import { Form, Field, Formik, useFormikContext } from "formik";
// import Debugger from "../utils/Debugger";

const LoanApplicationExceptions = ({ disableBtn, initialValues, setInitialValues }) => {
  const { agreement } = initialValues;
  const handleChange = (e) => {
    let data;
    if (e.target.name === "initialAgreement") {
      data = {
        [e.target.name]: e.target.checked,
      };
    } else {
      data = {
        [e.target.name]: e.target.value,
      };
    }

    setInitialValues({
      ...initialValues,
      agreement: {
        ...initialValues.exceptions,
        ...data,
      },
    });
  };

  const Context = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      !values.initialAgreement ? disableBtn(true) : disableBtn(false);
    }, [values]);
    return null;
  };

  return (
    <>
      {/* <Debugger data={agreement} /> */}

      <Formik initialValues={agreement}>
        <Form onChange={handleChange}>
          <div>
            <ol>
              <li>The applicant must be Muslim.</li>
              <li>
                Must be employed.
              </li>
              <li>
                Must be renting or in a non-Islamic mortgage. Priority will be
                given to those .
              </li>
              <li>Is at least eighteen (18) years of age.</li>
              <li>
                Is a current resident of the U.S.
                (minimum six-month employment history required).
              </li>
              <li>Is a U.S. citizen or legal resident.</li>
              <li>
                Can demonstrate creditworthiness and ability to repay a loan.
              </li>
              <li>Is not currently a homeowner.</li>
            </ol>

            <div className="form-group">
              <label htmlFor="exceptions">
                Please review our loan criteria and indicate any exceptions
                below:
              </label>
              <Field
                name="exceptions"
                className="form-control col-md-6"
                as="textarea"
              />


            </div>

            <div className="form-check">
              <Field
                name="initialAgreement"
                type="checkbox"
                className="form-check-input"
              />
              <label htmlFor="initialAgreement">
                I have reviewed and understand the criteria for consideration
                of an interest free loan.
                </label>
            </div>
          </div>
          <Context />
        </Form>
      </Formik>
    </>
  );
};

export default LoanApplicationExceptions;
