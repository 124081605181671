import "../../assets/css/singleApplication.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin } from "antd";
import { unNestObject, objectEmpty } from "../utils/helpers";

const SingleApplication = ({ initialValues, paramsId }) => {
  const [dataRequest, setDataRequest] = useState({
    fetching: false,
    error: false,
  });
  const [data, setData] = useState(
    (initialValues && unNestObject(initialValues)) || {}
  );

  const { fetching, error } = dataRequest;

  useEffect(() => {
    if (objectEmpty(data)) {
      setDataRequest({ fetching: true, error: false });
      axios
        .get(`/api/application/${paramsId}`)
        .then(({ data }) => {
          if (!data) {
            setDataRequest({ fetching: false, error: true });
          } else {
            setDataRequest({ fetching: false, error: false });
            setData(data);
          }
        })
        .catch((error) => {
          setDataRequest({ fetching: false, error: true });
        });
    } else {
      setDataRequest({ fetching: false, error: false });
    }
  }, []);

  const {
    id,
    exceptions,
    firstName,
    middleName,
    lastName,
    muslimName,
    dob,
    citizen,
    address,
    address2,
    city,
    state,
    zip,
    phone,
    email,
    living,
    rentAmount,
    filedBancrupcy,
    planForBankrupcy,
    maritalStatus,
    hasCoApplicant,
    coApplicantLivesTogether,
    wifeIsCoApplicant,
    coApplicantFirstName,
    coApplicantMiddleName,
    coApplicantLastName,
    coApplicantMuslimName,
    coApplicantDob,
    coApplicantAddress,
    coApplicantAddress2,
    coApplicantCity,
    coApplicantState,
    coApplicantZip,
    coApplicantLiving,
    coApplicantCitizen,
    coApplicantPhone,
    coApplicantEmail,
    coApplicantFiledBancrupcy,
    coApplicantPlanForBankrupcy,
    currentlyEmployed,
    currentEmployer,
    currentPosition,
    employedSince,
    employerAddress,
    employerAddress2,
    employerCity,
    employerState,
    employerZip,
    employerPhone,
    supervisorName,
    previousEmployer,
    previousPosition,
    grossSalary,
    monthlyIncome,
    coApplicantCurrentlyEmployed,
    coApplicantCurrentEmployer,
    coApplicantCurrentPosition,
    coApplicantEmployedSince,
    coApplicantEmployerAddress,
    coApplicantEmployerAddress2,
    coApplicantEmployerCity,
    coApplicantEmployerState,
    coApplicantEmployerZip,
    coApplicantEmployerPhone,
    coApplicantSupervisorName,
    coApplicantPreviousEmployer,
    coApplicantPreviousPosition,
    coApplicantGrossSalary,
    coApplicantMonthlyIncome,
    loanRequested,
    loanPurpose,
    loanImpact,
    appliedForOtherLoan,
    preferedHomeType,
    stayingInSameState,
    relocateWithinState,
    downpayment,
    mortgageOrRent,
    HOA,
    cableOrInternet,
    homePhone,
    cellPhone,
    homeUtilities,
    groceries,
    outing,
    carLoanOrLease,
    carMaintenance,
    carInsurance,
    homeInsurance,
    healthInsurance,
    pocketMedicalExpenses,
    totalCreditCardDebt,
    creditCardPayment,
    studentLoansPayment,
    tuitionForKids,
    childSupportOrAlimony,
    otherExpenses,
    emergencyName,
    emergencyRelationship,
    emergencyPhone,
    imamName,
    imamPhone,
    masjidName,
    referenceName1,
    referencePhone1,
    referenceName2,
    referencePhone2,
    referenceName3,
    referencePhone3,
    coApplicantMortgageOrRent,
    coApplicantHOA,
    coApplicantCableOrInternet,
    coApplicantHomePhone,
    coApplicantCellPhone,
    coApplicantHomeUtilities,
    coApplicantGroceries,
    coApplicantOuting,
    coApplicantCarLoanOrLease,
    coApplicantCarMaintenance,
    coApplicantCarInsurance,
    coApplicantHomeInsurance,
    coApplicantHealthInsurance,
    coApplicantPocketMedicalExpenses,
    coApplicantTotalCreditCardDebt,
    coApplicantCreditCardPayment,
    coApplicantStudentLoansPayment,
    coApplicantTuitionForKids,
    coApplicantChildSupportOrAlimony,
    coApplicantOtherExpenses,
    signatureName,
    agreeDate1,
    coSignatureName,
  } = data;

  if (fetching) {
    return <Spin size="large" className="spinner" />;
  } else if (error) {
    return (
      <div className="fetchError">
        <p>
          Unable to retrieve application data. Please try again at a later time!
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="application application-container">
        <h3>Loan Application</h3>
        <div className="section">
          <h5>Applicant Information: {id}</h5>

          <div className="field-group">
            <div className="field" data-name="First Name ">
              {firstName}
            </div>
            <div className="field" data-name="Middle Name ">
              {middleName}
            </div>
            <div className="field" data-name="Last Name ">
              {lastName}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Date of Birth ">
              {dob}
            </div>
            <div className="field" data-name="US Citizen ">
              {citizen}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Muslim Name ">
              {muslimName || "N/A"}
            </div>
            <div className="field" data-name="Currently Employed ">
              {currentlyEmployed}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Married ">
              {maritalStatus}
            </div>

            {hasCoApplicant === "Yes" && (
              <div className="field" data-name="Co-Applicant Lives together">
                {coApplicantLivesTogether}
              </div>
            )}

            {hasCoApplicant === "Yes" && maritalStatus === "Yes" && (
              <div className="field" data-name="Married ">
                {wifeIsCoApplicant}
              </div>
            )}
          </div>

          <div className="field-group">
            <div className="field" data-name="I am ">
              {living}
            </div>
            <div className="field" data-name="Rent Amount ">
              ${rentAmount}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Street Address ">
              {address}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Suite, Apt, Unit,etc.">
              {address2 || "N/A"}
            </div>
            <div className="field" data-name="City ">
              {city}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="State ">
              {state}
            </div>
            <div className="field" data-name="Zip ">
              {zip}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Phone # ">
              {phone}
            </div>
            <div className="field" data-name="Email ">
              {email}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Have filed for bankrupcy before ">
              {filedBancrupcy}
            </div>
            <div className="field" data-name="Will file for bankrupcy ">
              {planForBankrupcy}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Exceptions ">
              {exceptions}
            </div>
          </div>
        </div>

        {currentlyEmployed === "Yes" && (
          <div className="section">
            <h5>Employment Information</h5>

            <div className="field-group">
              <div className="field" data-name="Current Employer ">
                {currentEmployer}
              </div>
              <div className="field" data-name="Position Title ">
                {currentPosition}
              </div>
              <div className="field" data-name="Employed Since ">
                {employedSince}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Gross Income ">
                ${grossSalary}
              </div>
              <div className="field" data-name="Net Monthly Income ">
                ${monthlyIncome}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Employer Street Address ">
                {employerAddress}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Suite, Apt, Unit, etc.">
                {employerAddress2 || "N/A"}
              </div>
              <div className="field" data-name="City ">
                {employerCity}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="State ">
                {employerState}
              </div>
              <div className="field" data-name="Zip ">
                {employerZip}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Employer Phone ">
                {employerPhone}
              </div>
              <div className="field" data-name="Manager's Name ">
                {supervisorName}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Previous Employer ">
                {previousEmployer}
              </div>
              <div className="field" data-name="Previous Position Title ">
                {previousPosition}
              </div>
            </div>
          </div>
        )}

        {maritalStatus === "Yes" && (
          <div className="section">
            <h5>Co-Applicant Information</h5>

            <div className="field-group">
              <div className="field" data-name="First Name ">
                {coApplicantFirstName}
              </div>
              <div className="field" data-name="Middle Name ">
                {coApplicantMiddleName}
              </div>
              <div className="field" data-name="Last Name ">
                {coApplicantLastName}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Muslim Name ">
                {coApplicantMuslimName || "N/A"}
              </div>

              <div className="field" data-name="Date of Birth ">
                {coApplicantDob}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="US Citizen ">
                {coApplicantCitizen}
              </div>
              <div className="field" data-name="Currently Employed ">
                {coApplicantCurrentlyEmployed}
              </div>
              {coApplicantLivesTogether === "No" && (
                <div className="field" data-name="Street Address ">
                  {coApplicantLiving}
                </div>
              )}
            </div>

            <div className="field-group">
              <div className="field" data-name="Currently Employed ">
                {coApplicantCurrentlyEmployed}
              </div>
              <div className="field" data-name="Phone # ">
                {coApplicantPhone}
              </div>
              <div className="field" data-name="Email ">
                {coApplicantEmail}
              </div>
            </div>

            {coApplicantLivesTogether === "No" && (
              <>
                <div className="field-group">
                  <div className="field" data-name="Street Address ">
                    {coApplicantAddress}
                  </div>
                </div>

                <div className="field-group">
                  <div className="field" data-name="Suite, Apt, Unit,etc.">
                    {coApplicantAddress2 || "N/A"}
                  </div>

                  <div className="field" data-name="City ">
                    {coApplicantCity}
                  </div>
                </div>

                <div className="field-group">
                  <div className="field" data-name="State ">
                    {coApplicantState}
                  </div>

                  <div className="field" data-name="Zip ">
                    {coApplicantZip}
                  </div>
                </div>
              </>
            )}

            <div className="field-group">
              <div
                className="field"
                data-name="Have filed for bankrupcy before "
              >
                {coApplicantFiledBancrupcy}
              </div>
              <div className="field" data-name="Will file for bankrupcy ">
                {coApplicantPlanForBankrupcy}
              </div>
            </div>
          </div>
        )}

        {coApplicantCurrentlyEmployed === "Yes" && (
          <div className="section">
            <h5>Co-Applicant Employment Information</h5>

            <div className="field-group">
              <div className="field" data-name="Current Employer ">
                {coApplicantCurrentEmployer}
              </div>
              <div className="field" data-name="Position Title ">
                {coApplicantCurrentPosition}
              </div>
              <div className="field" data-name="Employed Since ">
                {coApplicantEmployedSince}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Gross Income ">
                ${coApplicantGrossSalary}
              </div>
              <div className="field" data-name="Net Monthly Income ">
                ${coApplicantMonthlyIncome}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Employer Street Address ">
                {coApplicantEmployerAddress}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Suite, Apt, Unit, etc.">
                {coApplicantEmployerAddress2 || "N/A"}
              </div>
              <div className="field" data-name="City ">
                {coApplicantEmployerCity}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="State ">
                {coApplicantEmployerState}
              </div>
              <div className="field" data-name="Zip ">
                {coApplicantEmployerZip}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Employer Phone ">
                {coApplicantEmployerPhone}
              </div>
              <div className="field" data-name="Manager's Name ">
                {coApplicantSupervisorName}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Previous Employer ">
                {coApplicantPreviousEmployer}
              </div>
              <div className="field" data-name="Previous Position ">
                {coApplicantPreviousPosition}
              </div>
            </div>
          </div>
        )}

        <div className="section">
          <h5>Loan Request Information</h5>
          <div className="field-group">
            <div className="field" data-name="Loan Amount ">
              ${loanRequested}
            </div>
            <div className="field" data-name="Applied for other loans ">
              {appliedForOtherLoan}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Staying in the same state ">
              {stayingInSameState}
            </div>
            <div
              className="field"
              data-name="Willing to relocate within state "
            >
              {relocateWithinState}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Down Payment ">
              ${downpayment}
            </div>
            <div className="field" data-name="Prefered Home Type ">
              {preferedHomeType}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Loan purpose ">
              {loanPurpose}
            </div>
            <div className="field" data-name="Loan impact ">
              {loanImpact}
            </div>
          </div>
        </div>

        <div className="section">
          <h5>Emergency Contact</h5>
          <div className="field-group">
            <div className="field" data-name="Contact Name ">
              {emergencyName}
            </div>
            <div className="field" data-name="Phone # ">
              {emergencyPhone}
            </div>
            <div className="field" data-name="Relationship ">
              {emergencyRelationship}
            </div>
          </div>
        </div>

        <div className="section">
          <h5>Imam Reference</h5>
          <div className="field-group">
            <div className="field" data-name="Imam Name ">
              {imamName}
            </div>
            <div className="field" data-name="Phone # ">
              {imamPhone}
            </div>
            <div className="field" data-name="Masjid Name ">
              {masjidName}
            </div>
          </div>
        </div>

        <div className="section">
          <h5>Other References</h5>
          <div className="field-group">
            <div className="field" data-name="Reference Name 1 ">
              {referenceName1}
            </div>
            <div className="field" data-name="Reference Phone # ">
              {referencePhone1}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Reference Name 2 ">
              {referenceName2}
            </div>
            <div className="field" data-name="Reference Phone # ">
              {referencePhone2}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Reference Name 3 ">
              {referenceName3}
            </div>
            <div className="field" data-name="Reference Phone # ">
              {referencePhone3}
            </div>
          </div>
        </div>

        <div className="section">
          <h5>Monthly Expenses</h5>

          <div className="field-group">
            <div className="field" data-name="Mortgage/Rent ">
              ${mortgageOrRent}
            </div>
            <div className="field" data-name="HOA ">
              ${HOA}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Cable/Internet ">
              ${cableOrInternet}
            </div>
            <div className="field" data-name="Home Phone ">
              ${homePhone}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Cell Phone ">
              ${cellPhone}
            </div>
            <div className="field" data-name="Electric/Gas/Water ">
              ${homeUtilities}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Groceries ">
              ${groceries}
            </div>
            <div className="field" data-name="Dinning out/Entertainment ">
              ${outing}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Car Loan/Lease ">
              ${carLoanOrLease}
            </div>
            <div className="field" data-name="Car Maintenance ">
              ${carMaintenance}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Car Insurance ">
              ${carInsurance}
            </div>
            <div className="field" data-name="Home Insurance ">
              ${homeInsurance}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Health Insurance ">
              ${healthInsurance}
            </div>
            <div className="field" data-name="Out of pocket medical expenses ">
              ${pocketMedicalExpenses}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Total Credit card debt ">
              ${totalCreditCardDebt}
            </div>
            <div className="field" data-name="Credit card monthly payment ">
              ${creditCardPayment}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Student Loans Payment ">
              ${studentLoansPayment}
            </div>
            <div className="field" data-name="Tuition for all children ">
              ${tuitionForKids}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Child Support/Alimony ">
              ${childSupportOrAlimony}
            </div>
          </div>

          <div className="field-group">
            <div className="field" data-name="Other expenses: ">
              {otherExpenses}
            </div>
          </div>
        </div>

        {hasCoApplicant === "Yes" && coApplicantLivesTogether === "No" && (
          <div className="section">
            <h5>Co Monthly Expenses</h5>

            <div className="field-group">
              <div className="field" data-name="Mortgage/Rent ">
                ${coApplicantMortgageOrRent}
              </div>
              <div className="field" data-name="HOA ">
                ${coApplicantHOA}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Cable/Internet ">
                ${coApplicantCableOrInternet}
              </div>
              <div className="field" data-name="Home Phone ">
                ${coApplicantHomePhone}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Cell Phone ">
                ${coApplicantCellPhone}
              </div>
              <div className="field" data-name="Electric/Gas/Water ">
                ${coApplicantHomeUtilities}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Groceries ">
                ${coApplicantGroceries}
              </div>
              <div className="field" data-name="Dinning out/Entertainment ">
                ${coApplicantOuting}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Car Loan/Lease ">
                ${coApplicantCarLoanOrLease}
              </div>
              <div className="field" data-name="Car Maintenance ">
                ${coApplicantCarMaintenance}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Car Insurance ">
                ${coApplicantCarInsurance}
              </div>
              <div className="field" data-name="Home Insurance ">
                ${coApplicantHomeInsurance}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Health Insurance ">
                ${coApplicantHealthInsurance}
              </div>
              <div
                className="field"
                data-name="Out of pocket medical expenses "
              >
                ${coApplicantPocketMedicalExpenses}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Total Credit card debt ">
                ${coApplicantTotalCreditCardDebt}
              </div>
              <div className="field" data-name="Credit card monthly payment ">
                ${coApplicantCreditCardPayment}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Student Loans Payment ">
                ${coApplicantStudentLoansPayment}
              </div>
              <div className="field" data-name="Tuition for all children ">
                ${coApplicantTuitionForKids}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Child Support/Alimony ">
                ${coApplicantChildSupportOrAlimony}
              </div>
            </div>

            <div className="field-group">
              <div className="field" data-name="Other expenses: ">
                {coApplicantOtherExpenses}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SingleApplication;
