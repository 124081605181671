import React from "react";
import { Collapse } from "antd";


const Process = () => {
  const { Panel } = Collapse;

  return (
    <div className="single-page about-page">
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>How It Works</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="welcome-wrap how-page container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="container accordion" id="accordionExample">
              <h3>Who is eligible?</h3>

              <div className="card">
                <Collapse bordered={false} defaultActiveKey={["1"]}>
                  <Panel header="Here are the eligibility criteria" key="1">
                    <ol>
                      <li>The applicant must be Muslim.</li>
                      <li>Must be employed.</li>
                      <li>
                        Must be renting or in a non-Islamic mortgage. Priority
                        will be given to those renting.
                      </li>
                      <li>Is at least eighteen (18) years of age.</li>
                      <li>
                        Is a current resident of the U.S. (minimum six-month
                        employment history required).
                      </li>
                      <li>Is a U.S. citizen or legal resident.</li>
                      <li>
                        Can demonstrate creditworthiness and ability to repay a
                        loan.
                      </li>
                      <li>Is not currently a homeowner.</li>
                    </ol>
                  </Panel>
                </Collapse>
              </div>

              <h3>How it works and is this right for me?</h3>
              <div className="card">
                <Collapse bordered={false}>
                  <Panel header="How it works?" key="2">
                    <p>
                      MFC's vision is to transform Muslims from being dependent
                      to becoming independent.
                    </p>

                    <p>
                      We have three streams of income; contributors, sponsors,
                      and lenders. The more contributions MFC receives the more
                      revenue we generate, thus enabling us to do more and serve
                      the community better.
                    </p>
                  </Panel>
                  <Panel header="How can I benefit from this?" key="3">
                    The purpose of MFC is to uplift the Muslim community and
                    allow it to have the foundation which we believe starts with
                    homeownership. This foundation allows every Muslim to start
                    changing their lives progressively to a better future.
                  </Panel>
                  <Panel
                    header="What is the maximum property value that I can purchase?"
                    key="4"
                  >
                    The maximum amount that will be granted is determined based
                    on income. MFC will allow current members renting or in an
                    interest based mortgage to purchase a property with the
                    collective of everyone's contributions and allow them to be
                    a homeowner without crossing the boundaries of Allah.
                  </Panel>
                  <Panel header="How does repayment work?" key="5">
                    After setting a repayment plan,
                    the homeowner will continue to pay their monthly dues. Once
                    the mortgage is fully paid, MFC will then transfer the home
                    title over to the homeowner.
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-6">
            <aside className="container sidebar sidebar-primary widget-area">
              <section
                id="custom_html-13"
                className="widget_text widget widget_custom_html"
              >
                <div className="widget_text widget-wrap">
                  <h3 className="widgettitle widget-title">
                    WE CAN MAKE A DIFFERENCE
                  </h3>
                  <div className="textwidget custom-html-widget">
                    Through the help of Allah and the Muslim community, MFC can
                    achieve its goals in improving the life of every Muslim, one
                    individual, and one family at a time.
                  </div>

                  <a
                    href="/forms/loan-application"
                    className="apply-btn btn gradient-bg mr-2"
                  >
                    Apply Now
                  </a>
                </div>
              </section>

              <section
                id="custom_html-13"
                className="widget_text widget widget_custom_html mt-5"
              >
                <div className="widget_text widget-wrap">
                  <h3 className="widgettitle widget-title">Become Known</h3>
                  <div className="textwidget custom-html-widget">
                    Take a five minute survey and be known in the muslim
                    community. We would to like account for every
                    Muslim living in the United States.
                  </div>

                  <a
                    href="/forms/be-known-survey"
                    className="apply-btn btn gradient-bg mr-2"
                  >
                    Become Known
                  </a>
                </div>
              </section>
            </aside>
          </div>
        </div>

        <div className="apply container my-5">
          <h3 style={{ textAlign: "center" }}>HOW TO APPLY</h3>
          <div className="one-fourth first">
            <div className="steps">STEP 1</div>
          </div>
          <div className="three-fourths">
            <span style={{ textDecoration: "underline" }}>
              Complete Application Form
            </span>
            – fill out a simple application form and have all paper work ready.
          </div>
          <div>
            <hr />
          </div>

          <div className="one-fourth first">
            <div className="steps">STEP 2</div>
          </div>
          <div className="three-fourths">
            <span style={{ textDecoration: "underline" }}>Interview</span> – We
            will call you to schedule an in person interview to evaluate whether
            or not you qualify and that all paper work is complete.
          </div>
          <div>
            <hr />
          </div>

          <div className="one-fourth first">
            <div className="steps">STEP 3</div>
          </div>
          <div className="three-fourths">
            <span style={{ textDecoration: "underline" }}>Evaluation</span> –
            MFC will evaluate, recommend and place you in queue to purchase a
            home.
          </div>
          <div>
            <hr />
          </div>

          <div className="one-fourth first">
            <div className="steps">STEP 4</div>
          </div>
          <div className="three-fourths">
            <span style={{ textDecoration: "underline" }}>Agreement</span>– Once
            it is your turn and have agreed to all terms and conditions to repay
            the community. MFC will fund you to purchase a home, but holds onto
            the title until full repayment.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
