import React from "react";
import greyClouds from "../../assets/images/grey-clouds.webp";
import SmallBanner from "../utils/SmallBanner";
import transparency from "../../assets/images/transparent.svg";
import dollar from "../../assets/images/dollar.svg";
import partner from "../../assets/images/partner.svg";


const About = () => {
  return (
    <div className="single-page about-page">
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>About Us</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="welcome-wrap">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 order-1 order-lg-1">
              <div className="welcome-content">
                <header className="entry-header">
                  <h2 className="entry-title">What We Do</h2>
                </header>

                <div className="entry-content mt-5">
                  <p>
                    Our volunteers and supporters, provide support and care for
                    underprivileged families in Atlanta, GA, and elsewhere in
                    the United States. We help Muslims gain; independent,
                    healthy, and successful lives by providing them with
                    long-lasting interest-free housing, resources and support,
                    training programs to improve their lives. We are committed
                    to fulfilling our obligation to help these families to
                    sustain themselves with a self-sufficient lifestyle.
                  </p>

                  <p>
                    We plan to make this organization the backbone of the Muslim
                    Ummah and be the go-to organization when Muslims and other
                    Muslim supporting organizations need funding.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 order-2 order-lg-2">
              <p className="quran-text">
                وَابْتَغِ فِيمَآ آتَاكَ اللَّهُ الدَّارَ الْآخِرَةَ وَلَا تَنْسَ
                نَصِيبَكَ مِنَ الدُّنْيَا وَأَحْسِنْ كَمَا أَحْسَنَ اللَّهُ
                إِلَيْكَ وَلَا تَبْغِ الْفَسَادَ فِي الْأَرْضِ إِنَّ اللَّهَ لَا
                يُحِبُّ الْمُفْسِدِينَ
                <span>
                  <b>٧٧</b>&#1757;
                </span>
              </p>
              <p className="quran-translation">
                But seek, through that which Allah has given you, the home of
                the Hereafter; and [yet], do not forget your share of the world.
                And do good as Allah has done good to you. And desire not
                corruption in the land. Indeed, Allah does not like corrupters."
              </p>
              <p className="quran-info">
                <span className="MFC">Surah Al-Qasas 28:77</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="features-section">
        <div className="container">
          <section className="text-center">
            <h2 className="h1-responsive font-weight-bold">
              Why <span className="MFC">MFC</span> ?
            </h2>
            <p className="lead grey-text w-responsive mx-auto mb-5">
              MFC's vision is to empower every Muslims to become independent.
              That is why we do not have investors or evaluate individuals with
              credit scores.
            </p>

            <div className="row">
              <div className="col-md-4">
                <img src={transparency} alt="Magnifying Glass" />

                <h5 className="font-weight-bold my-4">Transparent</h5>
                <p className="grey-text mb-md-0 mb-5">
                  We build through transparency. We believe that transparency is
                  key to gaining people's trust.
                </p>
              </div>
              <div className="col-md-4">
                <img src={dollar} alt="Dollar sign" />

                <h5 className="font-weight-bold my-4">Independent</h5>
                <p className="grey-text mb-md-0 mb-5">
                  As an independent organization we strive to make every Muslims
                  independent in every aspect of their lives.
                </p>
              </div>
              <div className="col-md-4">
                <img src={partner} alt="Handshake" />

                <h5 className="font-weight-bold my-4">Makes a difference</h5>
                <p className="grey-text mb-0">
                  We hope to be a positive impact in the life of every Muslim
                  and to spread our influence to change others.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>

      <SmallBanner
        text=" MFC is entirely funded through community donations which
        continually recycle to improve Muslims, allowing us to generate
        many times the original value to expand the benefits for our
        community."
        btnText="Contribute"
        path="/contribute"
        img={greyClouds}
      />
    </div>
  );
};

export default About;
