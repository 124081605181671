import React, { useEffect } from "react";
import { Form, Field, Formik, useFormikContext } from "formik";
// import Debugger from "../../utils/Debugger";

const VolunteerApplicationExceptions = ({
  disableBtn,
  initialValues,
  setInitialValues,
}) => {
  const onChange = (e) => {
    setInitialValues({
      ...initialValues,
      [e.target.name]: e.target.checked,
    });
  };

  const Context = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      !initialValues.initialAgreement ? disableBtn(true) : disableBtn(false);
    }, [values]);
    return null;
  };

  return (
    <>
      {/* <Debugger data={initialValues} /> */}

      <Formik initialValues={initialValues}>
        <Form onChange={onChange}>
          <div>
            <h4 className="ml-3">In order to volunteer for MFC applicant:</h4>
            <ol>
              <li>Must be Muslim.</li>
              <li>Is at least eighteen (18) years of age.</li>
              <li>Is a U.S. citizen or legal resident.</li>
            </ol>

            <div className="form-check">
              <Field
                name="initialAgreement"
                type="checkbox"
                className="form-check-input"
              />
              <label htmlFor="initialAgreement">
                I have reviewed and understand the criteria to volunteer for
                MFC.
              </label>
            </div>
          </div>
          <Context />
        </Form>
      </Formik>
    </>
  );
};

export default VolunteerApplicationExceptions;
