import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../assets/css/carousel.css";
import React, { Fragment } from "react";
import { Carousel } from "antd";
import handsGray from "../../assets/images/hands-gray.webp";
import handsWhite from "../../assets/images/hands-white.webp";
import donationGray from "../../assets/images/donation-gray.webp";
import donationWhite from "../../assets/images/donation-white.webp";
import charityGray from "../../assets/images/charity-gray.webp";
import charityWhite from "../../assets/images/charity-white.webp";
import home from "../../assets/images/home.webp";
import teamwork1 from "../../assets/images/teamwork1.webp";
import donation1 from "../../assets/images/donation1.webp";
import dove1 from "../../assets/images/dove1.webp";
import greyClouds from "../../assets/images/grey-clouds.webp";
import SmallBanner from "../utils/SmallBanner";

const Home = (props) => {
  return (
    <Fragment>
      <Carousel speed={1200} autoplay autoplaySpeed={6000}>
        <div className="hero hero-1">
          <div className="hero-content-overlay position-relative w-100 h-100">
            <div className="container col-12 col-lg-8 d-flex flex-column justify-content-center align-items-center">
              <header className="entry-header">
                <h1>Contribute</h1>
              </header>
              <div className="entry-content mt-4 text-center">
                <h2>
                  Our desire to change must be greater than our desire to stay
                  the same.
                </h2>
              </div>

              <footer className="entry-footer d-flex flex-wrap align-items-center mt-5">
                <a
                  href="/contribute"
                  target="_blank"
                  className="btn gradient-bg mr-2"
                >
                  Contribute Now
                </a>
                <a href="/about" className="btn orange-border">
                  Read More
                </a>
              </footer>
            </div>
          </div>
        </div>

        <div className="hero hero-2">
          <div className="hero-content-overlay position-relative w-100 h-100">
            <div className="legend container col-10 col-lg-8 d-flex flex-column justify-content-center align-items-center">
              <header className="entry-header">
                <h1>Our Mission</h1>
              </header>

              <div className="entry-content mt-4 text-center">
                <h2>
                  Bring positive change to the Muslim community by generating
                  strength through unity.
                </h2>
              </div>

              <footer className="entry-footer d-flex flex-wrap align-items-center mt-5">
                <a
                  href="/contribute"
                  target="_blank"
                  className="btn gradient-bg mr-2"
                >
                  Contribute Now
                </a>
                <a href="/about" className="btn orange-border">
                  Read More
                </a>
              </footer>
            </div>
          </div>
        </div>

        <div className="hero hero-3">
          <div className="hero-content-overlay position-relative w-100 h-100">
            <div className="container col-12 col-lg-8 d-flex flex-column justify-content-center align-items-center">
              <header className="entry-header">
                <h1>Our Vision</h1>
              </header>

              <div className="entry-content mt-4 text-center">
                <h2>
                  Empower Muslims to be independent in every aspect of their
                  life.
                </h2>
              </div>

              <footer className="entry-footer d-flex flex-wrap align-items-center mt-5">
                <a
                  href="/contribute"
                  target="_blank"
                  className="btn gradient-bg mr-2"
                >
                  Contribute Now
                </a>
                <a href="/about" className="btn orange-border">
                  Read More
                </a>
              </footer>
            </div>
          </div>
        </div>
      </Carousel>

      <div className="home-page-icon-boxes">
        <div className="container">
          <div className="row">
            <div className="cta-links-div col-12 col-md-6 col-lg-4 mt-4 mt-lg-0">
              <a href="/contribute" target="_blank" className="cta-links">
                <div className="icon-box active">
                  <figure className="d-flex justify-content-center">
                    <img src={handsGray} alt="hands" />
                    <img src={handsWhite} alt="hands" />
                  </figure>
                  <header className="entry-header">
                    <span className="entry-title">Become a Contributor</span>
                  </header>

                  <div className="entry-content">
                    <p>
                      Pledge to contribute a minimum of{" "}
                      <strong>one dollar</strong> a month and become a cause for
                      change in our Muslim community.
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className="cta-links-div col-12 col-md-6 col-lg-4 mt-4 mt-lg-0">
              <a href="/sponsor" className="cta-links">
                <div className="icon-box">
                  <figure className="d-flex justify-content-center">
                    <img src={donationGray} alt="Hand holding heart" />
                    <img src={donationWhite} alt="Hand holding heart" />
                  </figure>

                  <header className="entry-header">
                    <span className="entry-title">Become a sponsor</span>
                  </header>

                  <div className="entry-content">
                    <p>
                      As a sponsor you will play a vital role in advancing MFC
                      and shaping the future of Muslims in America and abroad.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="cta-links-div col-12 col-md-6 col-lg-4 mt-4 mt-lg-0">
              <a href="/lender" className="cta-links">
                <div className="icon-box">
                  <figure className="d-flex justify-content-center">
                    <img src={charityGray} alt="Heart" />
                    <img src={charityWhite} alt="Heart" />
                  </figure>

                  <header className="entry-header">
                    <span className="entry-title">Become a lender</span>
                  </header>
                  <div className="entry-content">
                    <p>
                      As a lender, you allow MFC to expedite the transformation
                      process and impact the lives of many Muslims looking for
                      change.
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="home-page-welcome">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <div className="welcome-content">
                <header className="entry-header">
                  <h2 className="entry-title">
                    Welcome to <strong className="MFC">MFC</strong>
                  </h2>
                </header>

                <div className="entry-content mt-5">
                  <p>
                    Muslims For Change is a 501(c)3 organization, EIN
                    84-3471843, created to make a difference in the lives of
                    Muslims both as individuals and a collective. The name
                    speaks for itself, but we believe that with the help of
                    every individual, we can make huge strides towards improving
                    the Muslim community. Let us start our journey together for
                    a well rounded Muslim community.
                  </p>
                </div>

                <div className="entry-footer mt-5">
                  <a href="/about" className="btn gradient-bg mr-2">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="video col-12 col-lg-6 mt-4 order-1 order-lg-2">
              <iframe
                src="https://www.youtube.com/embed/PMoPF4I8C3I"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <SmallBanner
        text="“When a man dies, all his deeds come to an end except for three: a continuous 
          charity, beneficial knowledge, and a righteous child who will pray for him.” 
          -Prophet Muhammad (PBUH)"
        btnText="Contribute"
        img={greyClouds}
        path="/contribute"
      />

      <div className="who-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              <div className="welcome-content">
                <header className="entry-header">
                  <h2 className="entry-title">Short-Term Plan</h2>
                </header>

                <div className="entry-content mt-5">
                  <p>
                    Utilizing collected funds from Muslim contributors,
                    sponsors, and lenders. We plan to fund Muslims with
                    non-interest bearing loans that enables them to have the
                    foundation to financial independence which starts with
                    homeownership.
                  </p>
                  <p>
                    Homeowners will then repay their non-interest bearing loan
                    back to MFC. The funds will then be recycled to fund
                    different initiatives.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <img src={home} alt="welcome" />
            </div>
          </div>

          <div className="assistance-options">
            <div className="main">
              <header>
                <h2>Application Filing Services</h2>

                <p>
                  We are currently offering application assistance services in
                  the Atlanta area. Call (404) 910-3713 and schedule an
                  appointment for assistance with filing your applications.
                </p>
              </header>
              <ul>
                <li>School / College</li>
                <li>Financial Aid</li>
                <li>Unemployment</li>
                <li>Business License</li>
                <li>Immigration</li>
                <li>&amp; Much More</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="our-causes">
        <div className="container">
          <section className="my-5">
            <h2 className="h1-responsive font-weight-bold text-center my-5">
              Our Goals
            </h2>
            <p className="lead grey-text text-center w-responsive mx-auto mb-5">
              After reaching our goal of homeownership for every member living
              in the United States with the help of Allah and the community, we
              aim to fulfill the needs of Muslims. Here are some of our
              long-term goals
            </p>
            <div className="row">
              <div className="col-lg-12">
                <div className="row mb-3">
                  <div className="col-1">
                    <i className="fa fa-angle-double-right"></i>
                  </div>
                  <div className="col-xl-10 col-md-11 col-10">
                    <h5 className="font-weight-bold mb-3">
                      Capacity Building to Support Interest-Free Housing
                    </h5>
                    <p className="grey-text">
                      We are committed to helping Muslim families and Muslim
                      organizations of all types, acquire and find the financial
                      resources to purchase properties that are not encumbered
                      with loans that require interest payments.
                    </p>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-1">
                    <i className="fa fa-angle-double-right"></i>
                  </div>
                  <div className="col-xl-10 col-md-11 col-10">
                    <h5 className="font-weight-bold mb-3">
                      Collective Assistance to Foster Economic Development
                    </h5>
                    <p className="grey-text">
                      Together we can do more. Based on feedback and necessity,
                      we aim to address all the needs and wants of the Muslim
                      community.
                    </p>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-1">
                    <i className="fa fa-angle-double-right"></i>
                  </div>
                  <div className="col-xl-10 col-md-11 col-10">
                    <h5 className="font-weight-bold mb-3">
                      Adult and Youth Training and Support
                    </h5>
                    <p className="grey-text mb-0">
                      We believe that adults and youth need life-long training
                      and support to navigate and understand important issues
                      that affect families and communities. We aim to make the
                      goal of reaching an affordable education for our community
                      a reality.
                    </p>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-1">
                    <i className="fa fa-angle-double-right"></i>
                  </div>
                  <div className="col-xl-10 col-md-11 col-10">
                    <h5 className="font-weight-bold mb-3">
                      Free Burial Services and Bereavement Support
                    </h5>
                    <p className="grey-text mb-0">
                      It is our duty as a community to provide a free burial for
                      all Muslims and fulfill the rights of one another. We
                      believe that Muslim families should not worry about the
                      cost of burying their loved ones when the time comes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className="home-page-limestone">
        <div className="container">
          <div className="section-heading">
            <h2 className="entry-title">Showing Appreciation</h2>
            <p>
              Abu Huraira reported: The Prophet, peace and blessings be upon
              him, said, “He has not thanked Allah who has not thanked people.”
              <br /> <strong>Sunan Abī Dāwūd 4811</strong>
            </p>
            <p className="mt-2">
              MFC would not be able to achieve its mission after Allah's help
              without the willingness of a community that strives towards
              change. Our desire to change must be greater than our desire to
              stay the same.
            </p>
          </div>
        </div>
      </div>
      {/* <div className="home-page-limestone">
        <div className="container">
          <div className="row align-items-end">
            <div className="coL-12 col-lg-6">
              <div className="section-heading">
                <h2 className="entry-title">Showing Appreciation</h2>
                <p>
                  Abu Huraira reported: The Prophet, peace and blessings be upon
                  him, said, “He has not thanked Allah who has not thanked
                  people.”
                  <br /> <strong>Sunan Abī Dāwūd 4811</strong>
                </p>
                <p className="mt-2">
                  MFC would not be able to achieve its mission without the help
                  of Allah and the willingness of a community that strives
                  towards change. Our desire to change must be greater than our
                  desire to stay the same.
                </p>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="milestones d-flex flex-wrap justify-content-between">
                <div className="col-12 col-sm-4 mt-5 mt-lg-0">
                  <div className="counter-box">
                    <div className="d-flex justify-content-center align-items-center">
                      <img src={teamwork1} alt="Team Holding Hands - Teamwork" />
                    </div>

                    <div className="d-flex justify-content-center align-items-baseline">
                      <div className="counter-k">0</div>
                    </div>

                    <h3 className="entry-title">
                      <b>Monthly Contributors</b>
                    </h3>
                  </div>
                </div>

                <div className="col-12 col-sm-4 mt-5 mt-lg-0">
                  <div className="counter-box">
                    <div className="d-flex justify-content-center align-items-center">
                      <img src={donation1} alt="Hands reaching out for water drops" />
                    </div>

                    <div className="d-flex justify-content-center align-items-baseline">
                      <div className="counter-k">0</div>
                    </div>

                    <h3 className="entry-title">
                      <b>Beneficiaries</b>
                    </h3>
                  </div>
                </div>

                <div className="col-12 col-sm-4 mt-5 mt-lg-0">
                  <div className="counter-box">
                    <div className="d-flex justify-content-center align-items-center">
                      <img src={dove1} alt="Dove flying" />
                    </div>

                    <div className="d-flex justify-content-center align-items-baseline">
                      <div className="counter-k">0</div>
                    </div>

                    <h3 className="entry-title">
                      <b>Sponsors</b>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Fragment>
  );
};

export default Home;
