import React, { useEffect, useState } from "react";
import { Form, Field, Formik, ErrorMessage, useFormikContext } from "formik";
import { objectEmpty, phone, requiredString } from "../../utils/helpers";
import * as Yup from "yup";
// import Debugger from "../utils/Debugger";
import FormError from "../../utils/FormError";
import { Reference } from "../../utils/Reference";
import { Button } from "antd";

const EmergencyAndReferences = ({ disableBtn, initialValues, setInitialValues }) => {
  const { emergencyReferencesValues } = initialValues;
  const [references, setReferences] = useState([]);

  useEffect(() => {
    setReferences([
      ...references,
      <Reference key={1} length={1} />,
    ]);
  }, []);
  const onChange = (e) => {
    setInitialValues({
      ...initialValues,
      emergencyReferencesValues: {
        ...initialValues.emergencyReferencesValues,
        [e.target.name]: e.target.value,
      },
    });
  };

  const renderReferenceFields = () => {
    const length = references.length + 1;

    if (length <= 3) {
      setReferences([
        ...references,
        <Reference key={length} length={length} />,
      ]);
    }
  };

  const validationSchema = Yup.object({
    emergencyName: requiredString,
    emergencyRelationship: requiredString,
    emergencyPhone: phone,
    imamName: requiredString,
    imamPhone: phone,
    masjidName: requiredString,
    referenceName1: requiredString,
    referencePhone1: phone
  });

  const Context = () => {
    // Grab values and submitForm from context
    const { errors, values, validateForm } = useFormikContext();
    useEffect(() => {
      validateForm().then((errors) =>
        !objectEmpty(errors) ? disableBtn(true) : disableBtn(false)
      );
    }, [errors]);
    return null;
  };

  return (
    <>
      <div className="container">
        {/* <Debugger data={emergencyReferencesValues} /> */}

        <Formik
          initialValues={emergencyReferencesValues}
          validationSchema={validationSchema}
        >
          <Form onChange={onChange} id="form">
            <div className="form-row">
              <div className="form-group col-lg-6">
                <label htmlFor="emergencyName">
                  Full Name of friend or relative other than spouse
                </label>
                <Field className="form-control col-md-6" name="emergencyName" />
                <ErrorMessage component={FormError} name="emergencyName" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-lg-6">
                <label htmlFor="emergencyPhone">Phone</label>
                <Field
                  className="form-control col-md-6"
                  name="emergencyPhone"
                />
                <ErrorMessage component={FormError} name="emergencyPhone" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4 col-lg-2">
                <label htmlFor="emergencyRelationship">Relationship</label>
                <Field
                  className="custom-select"
                  name="emergencyRelationship"
                  as="select"
                >
                  <option value="">Select...</option>
                  <option value="Sibiling">Sibiling</option>
                  <option value="Friend">Friend</option>
                  <option value="Relative">Relative</option>
                  <option value="Other">Other</option>
                </Field>
                <ErrorMessage
                  component={FormError}
                  name="emergencyRelationship"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-lg-6">
                <p>
                  Please provide us with a reference to your local Imam and
                  others who know you.
                </p>
                <label htmlFor="imamName">Imam Name</label>
                <Field className="form-control col-md-6" name="imamName" />
                <ErrorMessage component={FormError} name="imamName" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-lg-6">
                <label htmlFor="imamPhone">Phone</label>
                <Field className="form-control col-md-6" name="imamPhone" />
                <ErrorMessage component={FormError} name="imamPhone" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-lg-6">
                <label htmlFor="masjidName">Masjid Name</label>
                <Field className="form-control col-md-6" name="masjidName" />
                <ErrorMessage component={FormError} name="masjidName" />
              </div>
            </div>

            {references.map((references) => {
              return references;
            })}

            {references.length < 3 ? (
              <Button
                type="primary"
                className="mt-2 mb-5"
                onClick={() => renderReferenceFields()}
              >
                Add reference
              </Button>
            ) : (
              ""
            )}
            <Context />
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default EmergencyAndReferences;
