import React, { useEffect } from "react";
import { Form, Field, Formik, useFormikContext, ErrorMessage } from "formik";
// import Debugger from "../../utils/Debugger";
import { objectEmpty, requiredString } from "../../utils/helpers";
import * as Yup from "yup";
import FormError from "../../utils/FormError";

const LenderApplicationSignatures = ({
  disableBtn,
  initialValues,
  setInitialValues,
}) => {
  const onChange = (e) => {
    let data;
    if (e.target.name === "loanAgreementCheck") {
      data = {
        [e.target.name]: e.target.checked,
      };
    } else {
      data = {
        [e.target.name]: e.target.value,
      };
    }

    setInitialValues({
      ...initialValues,
      ...data,
    });
  };

  const validationSchema = Yup.object({
    signatureName: requiredString,
    agreeDate: Yup.date().required("Required"),
  });

  const Context = () => {
    // Grab values and submitForm from context
    const { errors, validateForm } = useFormikContext();
    useEffect(() => {
      validateForm().then((errors) =>
        !initialValues.loanAgreementCheck || !objectEmpty(errors)
          ? disableBtn(true)
          : disableBtn(false)
      );
    }, [errors]);
    return null;
  };

  return (
    <>
      {/* <Debugger data={initialValues} /> */}
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        <Form onChange={onChange}>
          <div className="container">
            <div>
              <div>
                <p>
                  Muslims For Change Inc. (MFC) is Borrowing from the Lender an
                  interest free loan in the sum of the Loan Amount. If the loan
                  is approved, MFC agrees to fully, absolutely and
                  unconditionally guarantee full payment of the Loan Amount as
                  fast as MFC can. The creditor also agrees that MFC will aim to
                  pay it’s dept in less than 6 year, but when it can’t they are
                  not to be pressured to pay full amount, but rather both the
                  lender and MFC should work together to create a full repayment
                  plan.
                </p>

                <p>
                  MFC by signing this Loan Agreement is thereby liable for
                  payment of the Loan Amount. This means that MFC is liable for
                  the full amount and, as such, could be called upon to repay
                  the total balance due provided that lender gives notice 6
                  months ahead of time.
                </p>

                <p>
                  MFC recognize that this Loan Agreement creates a binding
                  obligation on the organization until the Loan Amount is fully
                  paid. MFC also understands that typical loan repayment terms
                  for MFC are 30 to 72 months depending on the size of the loan,
                  beginning from one month after MFC receives the loan and signs
                  the loan Promissory Note. The actual repayment terms for MFC
                  may vary somewhat from these typical terms.
                </p>

                <p>
                  The lender understands and agrees that any and all disputes
                  which occur as a result of this loan and which are unable to
                  be resolved between the parties directly shall be subject to
                  binding arbitration by an MFC appointed Qadi (Judge). Should any
                  party fail to abide by the rulings of the Qadi (Judge), the other
                  party may pursue enforcement of the ruling to the degree
                  allowed by the Qadi (Judge) or MFC’s Sharia authorities. All parties
                  to any loan and or agreement agree to be bound by any and all
                  rulings of the appointed Qadi (Judge).
                </p>

                <p>
                  All matters affecting the interpretation of this Agreement and
                  the rights of the parties hereto shall be governed by the laws
                  of the State the agreement took place.
                </p>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="signatureName">Applicant Full Name</label>
                <Field
                  className="form-control"
                  name="signatureName"
                  placeholder="Applicant Name"
                />
                <ErrorMessage component={FormError} name="signatureName" />
              </div>

              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="agreeDate">Date</label>
                <Field type="date" className="form-control" name="agreeDate" />
                <ErrorMessage component={FormError} name="agreeDate" />
              </div>
            </div>

            <div className="form-check">
              <Field
                name="loanAgreementCheck"
                type="checkbox"
                className="form-check-input"
              />
              <label htmlFor="loanAgreementCheck">
                I have read this Agreement and I understand and fully agree to
                it.
              </label>
            </div>
          </div>
          <Context />
        </Form>
      </Formik>
    </>
  );
};

export default LenderApplicationSignatures;
