import React from 'react'
import { Field, ErrorMessage } from "formik";
import FormError from "../utils/FormError";

export const Reference = ({length}) => (
  <div className="mb-3">
    <h5 className="mt-2 mb-3">Reference {length} </h5>
    <div className="form-row">
      <div className="form-group col-lg-6">
        <label htmlFor={`referenceName${length}`}>Full Name</label>
        <Field className="form-control col-md-6" name={`referenceName${length}`} />
        <ErrorMessage component={FormError} name={`referenceName${length}`} />
      </div>
    </div>

    <div className="form-row">
      <div className="form-group col-lg-6">
        <label htmlFor={`referenceName${length}`}>Phone</label>
        <Field className="form-control col-md-6" name={`referencePhone${length}`} />
        <ErrorMessage component={FormError} name={`referencePhone${length}`} />
      </div>
    </div>
  </div>
)